import ExploreComponent from "../pageComponents/explore";
import { GetNFT } from "../../thirdSystem/merchandise";



const ExploreSotoshi = () => (
    <>
        <ExploreComponent merchandise={GetNFT(0,10)} />
    </>
);

export default ExploreSotoshi;