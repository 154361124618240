import { createGlobalStyle } from 'styled-components';
import ExploreComponent from "../pageComponents/explore";
import { NavLink, Outlet } from 'react-router-dom';
import Footer from '../components/footer';


const GlobalStyles = createGlobalStyle`
  .navbar {
    border-bottom: solid 1px rgba(255, 255, 255, .1) !important;
  }
`;

interface ExploreNav {
    id: number,
    name: string,
    path: string,
}
const navs: ExploreNav[] = [{ id: 1, name: "NFT", path: "nft" }, { id: 2, name: "Sotoshi", path: "sotoshi" }]
const ExploreLayout = () => (
    <>
        <section className='container'>
            <div style={{ display: 'flex' ,alignItems:'end',gap:'20px'}}>
                <h1>Explore</h1>
                {navs.map(nav => (
                    <NavLink to={`/explore/${nav.path}`} key={nav.id}
                        className={({ isActive, isPending }) => isActive ? "active" : "non-active"} >
                        <h3>{nav.name}</h3>
                    </NavLink>
                ))}
            </div>
            <Outlet />
            <Footer />
        </section>
    </>

);
export default ExploreLayout;