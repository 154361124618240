import { ReactNode } from "react"
import { Artifact } from "../../thirdSystem/merchandise"
import { Card, CardActionArea, CardContent, CardMedia, Chip, Paper, PaperProps, Typography } from "@mui/material";
import styled from "styled-components";

interface CardProps {
  data: Artifact,
  action_bar: (data: Artifact) => ReactNode,
  OnBodyClick: (data: Artifact) => void
}
const DisplayCard = (props:
  {
    data: Artifact,
    action_bar: (data: Artifact) => ReactNode,
    OnBodyClick: (data: Artifact) => void,
    paper_style?: PaperProps,
  }) => {
  return <Paper {...props.paper_style} variant="outlined">
    <Card>
      <CardActionArea onClick={e => props.OnBodyClick(props.data)}>
        <CardContent sx={{
          height: '15rem',
          display: 'flex',
          alignContent: 'center',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
          <CardMedia
            component='img'
            image={props.data.material_src_url}
            sx={{
              borderRadius: '3%',
              objectPosition: 'center',
              objectFit: 'scale-down',
              width: 'auto',
              height: 'auto',
              maxWidth: '100%',
              maxHeight: '100%',
            }}
          />
        </CardContent>
        <CardContent>
          <Typography variant='h6'>
            {props.data.name}
          </Typography>
          <Chip label={props.data.category} />
          <Typography variant='body2'>
            {props.data.price} BTC
          </Typography>
        </CardContent>
      </CardActionArea>
      {props.action_bar(props.data)}
    </Card>
  </Paper>
}

export default DisplayCard