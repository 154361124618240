import { useAccount, useAuth } from "@micro-stacks/react";
import { createGlobalStyle } from "styled-components";
import { address_purpose, useUserAccount } from "../components/context/userAccount";
import { Button } from "react-bootstrap";
import { AddressPurpose, BitcoinNetworkType, SignTransactionOptions, signTransaction } from 'sats-connect'
import { HandleInput } from "../common/util";
import React, { FormEvent, useState } from "react";
import { base64, hex } from '@scure/base'
import { InscribeOptionSchema, IncribeOption, InscribeOrdinal, ListSotoshi, InscribeTransactionEnum, InscribeTransaction, InscribeTransactionType, inscribe_option_schema } from "../thirdSystem/merchandise";


const GlobalStyles = createGlobalStyle`
  header#myHeader.navbar.sticky.white {
    background: #403f83;
    border-bottom: solid 1px #403f83;
  }
  header#myHeader.navbar .search #quick_search{
    color: #fff;
    background: rgba(255, 255, 255, .1);
  }
  header#myHeader.navbar.white .btn, .navbar.white a, .navbar.sticky.white a{
    color: #fff;
  }
  header#myHeader .dropdown-toggle::after{
    color: rgba(255, 255, 255, .5);
  }
  header#myHeader .logo .d-block{
    display: none !important;
  }
  header#myHeader .logo .d-none{
    display: block !important;
  }
  @media only screen and (max-width: 1199px) {
    .navbar{
      background: #403f83;
    }
    .navbar .menu-line, .navbar .menu-line1, .navbar .menu-line2{
      background: #fff;
    }
    .item-dropdown .dropdown a{
      color: #fff !important;
    }
  }
`;


const Test = () => {
  const a: InscribeTransactionEnum = 'commit_tx';
  const { openAuthRequest, isRequestPending, signOut, isSignedIn } = useAuth();
  const account_data = useAccount();
  const { account, is_signed_out } = useUserAccount();
  const [sotoshi_list, set_sotoshi_list] = useState("");
  const [sign_info, setSignInfo] = useState<InscribeTransaction>({ commit_tx: '', reveal_tx: '' });
  const address_info = account?.addresses.find(add => add.purpose === address_purpose.Enum.ordinals);
  const [inscribe_info, set_inscribe_info] = useState<IncribeOption>({
    ...inscribe_option_schema.parse({
      self_address: address_info?.address,
      dest_address: address_info?.address,
    }),
  })
  const [inscribe_error, set_inscribe_error] = useState('');
  const [signed_info, setSignedInfo] = useState<InscribeTransaction>({ commit_tx: '', reveal_tx: '' });


  const handlInput = HandleInput<InscribeTransaction>((name, value) => {
    setSignInfo(curr => ({ ...curr, [name]: value }))
  });
  const handlInscribeInput = HandleInput<IncribeOption>((name, value) => {
    set_inscribe_info(curr => ({ ...curr, [name]: value }))
  });
  const list_sotoshi = async () => {
    if (is_signed_out) {
      set_sotoshi_list("尚未連接錢包");
    }
    else {
      ListSotoshi(address_info!.address, true)
        .then(res => set_sotoshi_list(JSON.stringify(res.data)))
        .catch(reason => set_sotoshi_list(`fetch 失敗 reason:${reason}`));
    }
  }
  const inscribe_ordinal = () => {
    InscribeOrdinal<InscribeTransaction>(inscribe_info)
      .then(res => setSignInfo(res.data))
      .catch(error => set_inscribe_error(JSON.stringify(error)));
  };

  const sign_raw_tx = async (psbtBase64: string, tx_type: InscribeTransactionEnum) => {
    // const test = btoa
    // hex.decode
    // const hexe = hex.encode(psbtBase64)
    // const encode = base64.encode(psbtBase64)
    const signPsbtOptions: SignTransactionOptions = {
      payload: {
        network: {
          type: BitcoinNetworkType.Testnet
        },
        message: 'Sign Transaction',
        psbtBase64,
        broadcast: false,
        // inputsToSign: [{
        //   address: "33TKH4kkiFPyTLDNmdNsLggyLeAYre57Qm",
        //   signingIndexes: [1],
        // }],
        inputsToSign: [{
          address: address_info!.address,
          signingIndexes: [1],
        }],
      },
      onFinish: (response) => {
        //console.log(response.psbtBase64)
        switch (tx_type) {
          case InscribeTransactionType.enum.commit_tx:
            setSignedInfo(curr => ({ ...curr, reveal_tx: JSON.stringify(response) }));
            break;
          case InscribeTransactionType.Enum.reveal_tx:
            setSignedInfo(curr => ({ ...curr, commit_tx: JSON.stringify(response) }));
            break;
        }
        alert(response.psbtBase64)
      },
      onCancel: () => alert('Canceled'),
    }
    console.log()
    console.log(sign_info);
    //console.log(element.value);
    //console.log(signPsbtOptions);

    await signTransaction(signPsbtOptions);
  }

  return (
    <div>
      <GlobalStyles />
      <section className='jumbotron breadcumb no-bg' style={{ backgroundImage: `url(${'./img/background/subheader.jpg'})` }}>
        <div className='mainbreadcumb'>
          <div className='container'>
            <div className='row'>
              <div className="col-md-12 text-center">
                <h1>Test</h1>
                <p>Wallet User Information</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='container'>
        <div className='row'>
          <div className='col-lg-8 mb-3'>
            <h3>Sign Transaction</h3>
            <div>
              <form className="formcontact">
                <input type='button' value="list sotoshi" onClick={list_sotoshi} className="btn btn-main color-2"></input>
                {sotoshi_list}
                <div>
                  <label htmlFor={InscribeOptionSchema.enum.testnet} style={{ display: 'inline', width: '50%' }}> test net</label>
                  <input
                    id={InscribeOptionSchema.enum.testnet}
                    name={InscribeOptionSchema.enum.testnet}
                    type='checkbox'
                    checked={inscribe_info.testnet}
                    onClick={e => set_inscribe_info(curr => (
                      {
                        ...curr,
                        testnet: !curr.testnet,
                      }))}
                    style={{ display: 'inline', width: '50%' }}>
                  </input>

                </div>
                <label>self address</label>
                <input
                  name={InscribeOptionSchema.enum.self_address}
                  type='text'
                  value={inscribe_info.self_address}
                  onInput={handlInscribeInput}>
                </input>
                <label>dest address</label>
                <input
                  name={InscribeOptionSchema.enum.dest_address}
                  type='text'
                  value={inscribe_info.dest_address}
                  onInput={handlInscribeInput}>
                </input>
                <label htmlFor={InscribeOptionSchema.enum.testnet} style={{ display: 'inline', width: '50%' }}> custom sat </label>
                <input
                  name={InscribeOptionSchema.enum.custom_sat}
                  type='checkbox'
                  checked={inscribe_info.custom_sat}
                  onClick={e => set_inscribe_info(curr => (
                    {
                      ...curr,
                      custom_sat: !curr.custom_sat,
                    }))}
                  style={{ display: 'inline', width: '50%' }}>
                </input>
                <label>file location</label>
                <input
                  name={InscribeOptionSchema.enum.file_location}
                  type='text'
                  value={inscribe_info.file_location}
                  onInput={handlInscribeInput}>
                </input>
                <label>fee_rate</label>
                <input
                  name={InscribeOptionSchema.enum.fee_rate}
                  type='text'
                  value={inscribe_info.fee_rate}
                  onInput={handlInscribeInput}>
                </input>
                <label>postage</label>
                <input
                  name={InscribeOptionSchema.enum.postage}
                  type='text'
                  value={inscribe_info.postage}
                  onInput={handlInscribeInput}>
                </input>
                <label>txid</label>
                <input
                  name={InscribeOptionSchema.enum.txid}
                  type='text'
                  value={inscribe_info.txid}
                  onInput={handlInscribeInput}>
                </input>
                <label>vout</label>
                <input
                  name={InscribeOptionSchema.enum.vout}
                  type='text'
                  value={inscribe_info.vout}
                  onInput={handlInscribeInput}>
                </input>
                <label>offset</label>
                <input
                  name={InscribeOptionSchema.enum.offset}
                  type='text'
                  value={inscribe_info.offset}
                  onInput={handlInscribeInput}>
                </input>
                <label>Inscribe Info</label>
                <textarea value={JSON.stringify(inscribe_info)}></textarea>
                <input type='button' value="Send Inscribe Info" onClick={inscribe_ordinal} className="btn btn-main color-2"></input>
                {
                  inscribe_error
                }
                <label>commit_tx</label>
                <input type='text'
                  name={InscribeTransactionType.enum.commit_tx}
                  value={sign_info.commit_tx}
                  onInput={handlInput}
                ></input>
                <input type='button' value="sign commit_tx" onClick={(e) => sign_raw_tx(sign_info.commit_tx!, InscribeTransactionType.enum.commit_tx)} className="btn btn-main color-2"></input>
                <label>signed commit_tx</label>
                <textarea value={signed_info.commit_tx}></textarea>
                <label>reveal_tx</label>
                <input type='text'
                  name={InscribeTransactionType.enum.reveal_tx}
                  value={sign_info.reveal_tx}
                  onInput={handlInput}></input>
                <input type="button" value="sign reveal_tx" onClick={(e) => sign_raw_tx(sign_info.reveal_tx!, InscribeTransactionType.enum.reveal_tx)} className="btn btn-main color-2"></input>
                <label>signed reveal_tx</label>
                <textarea value={signed_info.reveal_tx}></textarea>
              </form>
            </div>
            <h3>Sat Connect</h3>
            <div className="form-side">
              <form className="formcontact">
                <label>JWT_Token</label>
                <textarea disabled={true} value={JSON.stringify(account)}></textarea>
                {<>
                  <label>Ordinal Address</label>
                  <input type="text" disabled={true} className="form-control" name="is_signed_in" value={address_info?.address} />
                  <label>Ordinal Public Key</label>
                  <input type="text" disabled={true} className="form-control" name="app_private_key" placeholder={`${account_data.appPrivateKey}`} value={address_info?.publicKey} />
                </>

                }
                {/* <label>StxAddress</label>
                <input type="text" disabled={true} className="form-control" name="stxAddress" placeholder={`${account_data.stxAddress}`} required />
                <label>RawAddress</label>
                <input type="text" disabled={true} className="form-control" name="rawAddress" placeholder={`${account_data.rawAddress}`} required />
                <label>IdentityAddress</label>
                <input type="text" disabled={true} className="form-control" name="IdentityAddress" placeholder={`${account_data.identityAddress}`} required />
                <label>DecentralisedID</label>
                <input type="text" disabled={true} className="form-control" name="DecentralisedID" placeholder={`${account_data.decentralizedID}`} required />
                <label>ProfileUrl</label>
                <input type="text" disabled={true} className="form-control" name="ProfileUrl" placeholder={`${account_data.profileUrl}`} required />
                <input type="email" className="form-control" name="user_email" placeholder="Your Email" required />
                <input type="text" className="form-control" name="user_phone" placeholder="Your Phone" required />
                <textarea name="message" className="form-control" placeholder="Your Message" required />
                <div id='success' className='hide'>Your message has been sent...</div>
                <div id='failed' className='hide'>Message failed...</div>
                <input type='submit' id='buttonsent' value='Submit Now' className="btn btn-main color-2" /> */}
              </form>
            </div>
            <h3>Micro Stack</h3>
            <div className="form-side">
              <form className="formcontact">
                <label>JWT_Token</label>
                {/* <textarea disabled={true} value={JSON.stringify(user.micro_stack_account?.user)}></textarea> */}
                <label>isSignedIn</label>
                <input type="text" disabled={true} className="form-control" name="is_signed_in" placeholder={`${isSignedIn}`} required />
                <label>AppPrivateKey</label>
                <input type="text" disabled={true} className="form-control" name="app_private_key" placeholder={`${account_data.appPrivateKey}`} required />
                <label>StxAddress</label>
                <input type="text" disabled={true} className="form-control" name="stxAddress" placeholder={`${account_data.stxAddress}`} required />
                <label>RawAddress</label>
                <input type="text" disabled={true} className="form-control" name="rawAddress" placeholder={`${account_data.rawAddress}`} required />
                <label>IdentityAddress</label>
                <input type="text" disabled={true} className="form-control" name="IdentityAddress" placeholder={`${account_data.identityAddress}`} required />
                <label>DecentralisedID</label>
                <input type="text" disabled={true} className="form-control" name="DecentralisedID" placeholder={`${account_data.decentralizedID}`} required />
                <label>ProfileUrl</label>
                <input type="text" disabled={true} className="form-control" name="ProfileUrl" placeholder={`${account_data.profileUrl}`} required />
                {/* <input type="email" className="form-control" name="user_email" placeholder="Your Email" required />
                <input type="text" className="form-control" name="user_phone" placeholder="Your Phone" required />
                <textarea name="message" className="form-control" placeholder="Your Message" required />
                <div id='success' className='hide'>Your message has been sent...</div>
                <div id='failed' className='hide'>Message failed...</div>
                <input type='submit' id='buttonsent' value='Submit Now' className="btn btn-main color-2" /> */}
              </form>
            </div>
          </div>

          {/* <div className='col-md-4'>

            <div className="padding40 box-rounded mb30">
              <h3>US Office</h3>
              <address className="s1">
                <span><i className="id-color fa fa-map-marker fa-lg"></i>08 W 36th St, New York, NY 10001</span>
                <span><i className="id-color fa fa-phone fa-lg"></i>+1 333 9296</span>
                <span><i className="id-color fa fa-envelope-o fa-lg"></i><span className='btn'>contact@example.com</span></span>
                <span><i className="id-color fa fa-file-pdf-o fa-lg"></i><span className='btn'>Download Brochure</span></span>
              </address>
            </div>

            <div className="padding40 box-rounded mb30 text-light">
              <h3>AU Office</h3>
              <address className="s1">
                <span><i className="fa fa-map-marker fa-lg"></i>100 Mainstreet Center, Sydney</span>
                <span><i className="fa fa-phone fa-lg"></i>+61 333 9296</span>
                <span><i className="fa fa-envelope-o fa-lg"></i><span className='btn'>contact@example.com</span></span>
                <span><i className="fa fa-file-pdf-o fa-lg"></i><span className='btn'>Download Brochure</span></span>
              </address>
            </div>

          </div> */}

        </div>
      </section>
    </div>
  );
}

export default Test