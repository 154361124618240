import { useEffect, useState } from "react";
import { GetNFT } from "../../thirdSystem/merchandise";
import NFTshops from "../pageComponents/nftShops";
import { useCart } from "../context/cart";
import { useNavigate } from "react-router-dom";



const ExploreNFT = () => {
    const [nft, setNFT] = useState(GetNFT(0, 10));
    const cart = useCart();
    const navigate = useNavigate();

    const to_detail_page = (id: number) => {
        navigate(`/ItemDetail/${id}`)
    };

    useEffect(() => {
        const addItem = () => {
            setNFT((current) => {
                const pageSize = 10;
                const newItems = GetNFT(current.length, current.length + pageSize);
                return [...current, ...newItems]
            })
        }
        const onScroll = () => {
            // const scroll_length = window.innerHeight + window.scrollY;
            // const total_height = document.body.offsetHeight;
            // console.log(`scroll_length: ${scroll_length}}`);
            // console.log(`total_height: ${total_height}}`);
            // console.log(`at bottom: ${scroll_length >= total_height}}`);
            if (Math.ceil(window.innerHeight + window.scrollY) >= document.body.offsetHeight - 1) {
                //console.log(`onScroll:`);
                addItem()
            }
        }
        document.addEventListener('scroll', onScroll, {
            passive: true,
        });

        return () => {
            document.removeEventListener('scroll', onScroll);
        };
    }, [])
    //console.log(`end:`);
    //console.log(nft);

    return (
        <>
            <NFTshops merchandise={nft} buy={cart.addItem} click_detail={to_detail_page} />
        </>
    )
};

export default ExploreNFT;