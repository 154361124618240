
import Footer from '../components/footer';
import { createGlobalStyle } from 'styled-components';
import { Box, Button, Select, Stack, TextField, Typography, styled } from "@mui/material";
import { NavLink, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Container } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile, faGem, faTags } from "@fortawesome/free-solid-svg-icons";
import Grid from '@mui/material/Unstable_Grid2'
import { SatoshiDropDown } from '../components/SatoshiDropDown';
import { UtxoSatoshi, inscribe_option_schema } from '../../thirdSystem/merchandise';
import { number, z } from 'zod';
import { useState } from 'react';
import { HandleInput } from '../../common/util';

const GlobalStyles = createGlobalStyle`
  @media only screen and (max-width: 1199px) {
    .navbar{
      background: #403f83;
    }
    .navbar .menu-line, .navbar .menu-line1, .navbar .menu-line2{
      background: #fff;
    }
    .item-dropdown .dropdown a{
      color: #fff !important;
    }
  }
`;
const GridIcon = styled(Grid)(({ theme }) =>
  theme.unstable_sx({
    display: "flex",

  })
)
const IconButton = styled(Button)({
  minWidth: '80%',
  minHeight: '10rem',
  justifyContent: 'start',

})
const transaction_fee_props = z.object({
  name: z.string(),
  fee: z.number(),
  description: z.string(),
  on_selected: z.function().args(z.number(), z.number())
})
type TransactionFeeProps = z.infer<typeof transaction_fee_props>;
const TransactionFeeItem = (props: TransactionFeeProps) => {

  return <>
    <Stack style={{ minHeight: "200px" }} spacing={3} mt={3}>
      <Typography variant='h5'>{props.name}</Typography>
      <Typography variant='h6'>${props.fee}</Typography>
      <Typography variant='body1'>{props.description}</Typography>
    </Stack>
  </>
}
const TransactionFeeButtion = styled(Button)({
  textTransform: "none",
})

const Inscribe = () => {
  //window.scrollTo(0, 0);
  //✨Congratulations on completing your creation!
  const [searchParams] = useSearchParams();

  const img_src = searchParams.get("img") ?? "";

  const satoshi: UtxoSatoshi[] = [
    { output: 'ajddfafajisjp3j143p4134', start: 1233220826585001, end: 1233220826586000, offset: 999 },
    { output: 'adfhalfhadufhadfui13434', start: 1433220826596100, end: 1433220826596123 }];


  const [inscribe_option, set_inscribe_option] = useState(inscribe_option_schema.parse({}))
  const default_transaction_fee_option = [
    {
      name: "Standard",
      fee: 5.05,
      description: "Lowest fee for slower minting completion",
    },
    {
      name: "Express",
      fee: 5.83,
      description: "Mid-range fee for faster possible minting",
    },
    {
      name: "Priority",
      fee: 8.15,
      description: "Highest fee for prioritized minting completion",
    },
  ];
  const [custom_fee, set_custom_fee] = useState(0);
  const [show_custom_fee_text, open_custom_fee_text] = useState(false);
  const [on_selected, set_on_selected] = useState(0);
  const select_fee = (fee: number, index: number) => {
    set_on_selected(index);
    open_custom_fee_text(false);
    set_inscribe_option(curr => ({ ...curr, fee_rate: fee }))
  }

  const transacion_fee_option: TransactionFeeProps[] = [...default_transaction_fee_option.map((fee) => ({ ...fee, on_selected: select_fee })), {
    name: "Negotiable",
    fee: custom_fee,
    description: "Choose the price you prefer ",
    on_selected: (fee: number, index: number) => {
      select_fee(fee, index);
      open_custom_fee_text(true)
    },
  }];
  //console.log(img_src);
  const navigate = useNavigate();
  const inscribe = () => {
    const inscribe_success_description = "✨Congratulations on completing your inscription!"
    navigate(`/createPanel/${inscribe_success_description}`)
  }
  return (<>
    <GlobalStyles />
    <section className='container'>
      <img src={img_src} alt=''></img>
      <Typography variant='h5' marginY={'1rem'}>Select Your Satoshi and Inventory</Typography>
      <Stack rowGap={'1rem'}>
        <Typography variant='h6'>Select Satoshi</Typography>
        <Stack direction={{ lg: "row" }} spacing={5} >
          <SatoshiDropDown utxo={satoshi} />
        </Stack>
        <Typography variant='h6'>Supply</Typography>
        <TextField size='small' placeholder='1' helperText='the number of satoshi that you want to mint'></TextField>
      </Stack>
      <Typography variant='h5' marginY={'1rem'}>Choose Your Transaction Fee and Address</Typography>
      <Stack rowGap={'1rem'}>
        <Typography variant='h6'>Transaction Fee</Typography>
        <Grid container spacing={3}>
          {
            transacion_fee_option.map((option, index) => (<>
              <Grid xs={6} md={3}>
                <TransactionFeeButtion key={index} fullWidth variant='outlined' onClick={e => option.on_selected(option.fee, index)} style={{ background: on_selected === index ? "#f5f5f7" : "white" }}>
                  <TransactionFeeItem {...option} />
                </TransactionFeeButtion>
              </Grid>
            </>))
          }
        </Grid>
        {/* {JSON.stringify(inscribe_option)} */}
        <TextField type='number' hidden={!show_custom_fee_text}
          onChange={e => {
            const fee = z.coerce.number().parse(e.target.value);
            set_custom_fee(fee);
            set_inscribe_option(curr => ({
              ...curr,
              fee_rate: fee
            }))
          }}></TextField>
        <Typography variant='h6'>Address</Typography>
        <TextField size='small' placeholder='bc1pv4x77a8372pp5wmcfdza2rhp860a2dcl60w02sf2yt29uwvxfl0sxvw4a5' helperText="Enter the address of the person you want to send to"></TextField>
      </Stack>
      <Box mt={"3rem"} display={'flex'} justifyContent={'start'}><Button size='large' variant='contained' onClick={inscribe}>Inscriptions</Button></Box>
    </section>
    <Footer />
  </>)
}

export default Inscribe