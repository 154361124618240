import { createContext, useContext, useMemo, useState } from "react"
import { Merchandise } from "../../common/merchandise"



export interface Cart {
    items: Merchandise[],
    total_item: number,
    total_price: number,
    addItem: (item: Merchandise) => void,
    remove_item: (id: number) => void,
    remove_all:()=>void,
}
const CartContext = createContext<Cart>({
    items: [],
    addItem: (item) => { },
    total_item: 0,
    total_price: 0,
    remove_item: (id) => { },
    remove_all: () => { }
});

export const CartProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
    const [items, setItems] = useState<Merchandise[]>([])
    const total_item = useMemo(() => {
        return items.map(item => item.amount).reduce((acc, curr) => acc + curr, 0)
    }, [items])
    const total_price = useMemo(() => {
        return items.map(item => item.amount * item.price).reduce((acc, curr) => acc + curr, 0)
    }, [items])
    const cart: Cart = {
        items,
        total_item: total_item,
        total_price,
        addItem: (item) => setItems(curr => {
            const exist = curr.find(exist => exist.id === item.id) ?? { ...item, amount: 0 };
            const amount_plus_one: Merchandise = { ...exist, amount: exist.amount + 1 };
            return [...curr.filter(i => i.id !== item.id), amount_plus_one];
        }),
        remove_item: (id) => {
            setItems(curr => curr.filter(item => item.id !== id))
        },
        remove_all: () => {
            setItems([])
        }
    }
    return <CartContext.Provider value={cart}>
        {children}
    </CartContext.Provider>
}
export const useCart = () => useContext(CartContext)