import Select, { GetOptionLabel, GroupBase, OptionProps, Options, SelectInstance } from 'react-select';
import { UtxoSatoshi, utxo_satoshi_schema } from "../../thirdSystem/merchandise";
import { createRef, useRef, useState } from 'react';

interface SatoshiProps {
    utxo: UtxoSatoshi[],
}
interface Satoshi {
    ordinal: number
    utxo: string
}

const utxo_to_satoshi = (utxo: UtxoSatoshi) => [...Array(utxo.end! - utxo.start!).keys()].map(k => (
    {
        utxo: utxo.output!,
        ordinal: utxo!.start! + k
    }))
export const SatoshiDropDown = (props: SatoshiProps) => {
    const [satoshi_range, set_satoshi_range] = useState<Satoshi[]>(utxo_to_satoshi(props.utxo[0] ?? utxo_satoshi_schema.parse({})));

    const satoshi_ref = createRef<SelectInstance<Satoshi, false, GroupBase<Satoshi>>>();
    const create_utxo_range = (utxo: UtxoSatoshi) => {
        satoshi_ref.current?.clearValue();
        return set_satoshi_range(utxo_to_satoshi(utxo));
    }
    return (<>
        <Select
            defaultValue={props.utxo.find(_ => true)}
            name="sotoshi"
            options={props.utxo}
            isSearchable={true}
            getOptionLabel={sat => `${sat.output}: ${sat.start}~${sat.end}`}
            getOptionValue={sat => `${sat.output}${sat.start}${sat.end}`}
            onChange={utxo => create_utxo_range(utxo!)}
            styles={{
                control(styles) {
                    return { ...styles, minWidth: '35rem' }
                },
            }}
        />
        <Select
            name="sotoshi"
            ref={satoshi_ref}
            defaultValue={satoshi_range.find(_ => true)}
            options={satoshi_range}
            isSearchable={true}
            getOptionLabel={sat => `${sat.ordinal}`}
            getOptionValue={sat => `${sat.ordinal}`}
            styles={{ control: styles => ({ ...styles, minWidth: '16rem' }) }}
        />
    </>
    );
}

