import { faArrowUpRightFromSquare, faTrash, faXmark } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Cart as CartItem } from "../context/cart";
import { CSSProperties } from "styled-components";
import { Box, Button, Chip, IconButton, Stack, Typography } from "@mui/material";
import { Merchandise } from "../../common/merchandise";
import Grid from '@mui/material/Unstable_Grid2'

interface CartProps {
    cart: CartItem
    on_leave_menu:()=>void,
}
interface ItemLayoutProps {
    merchandise: Merchandise,
    onRemove: (merchandise: Merchandise) => void,
}
const ItemLayout = ({ merchandise, onRemove }: ItemLayoutProps) => {

    return (
        <Grid container key={merchandise.id} spacing={3} mx={{sm:"5px"}}>
            <Grid xs={3} justifyContent={"center"} display={"flex"}>
                <img src={merchandise.img_src} alt="" style={{borderRadius:'5px', alignSelf: 'stretch', width: '72px', objectFit: 'cover', maxHeight: 72 }}></img>
            </Grid>
            <Grid xs={3}>
                <Chip label={merchandise.category} size="small"/>
                <h4>{merchandise.title}</h4>
            </Grid>
            <Grid xs={3}>
                <h6 style={{ textAlign: 'right' }}>x{merchandise.amount}</h6>
                <h6 style={{ textAlign: 'right' }}>{merchandise.price} BTC</h6>
            </Grid>
            <Grid xs={3}>
                <IconButton size="small" onClick={(_) => onRemove(merchandise)}>
                    <FontAwesomeIcon icon={faTrash} />
                </IconButton>
            </Grid>
        </Grid>
    )
}
export const Cart = (props: CartProps) => {

    const title_style: CSSProperties = { display: 'flex', justifyContent: 'space-around', alignItems: 'start' };
    const remove_item = (item: Merchandise) => {
        props.cart.remove_item(item.id);
    }
    const remove_all_items = () => {
        props.cart.remove_all();
    }

    return (<Stack
        minWidth={{xs:"100vw",sm:"50vw",md:"35vw"}}>
        <Box id='titile' display={"flex"} justifyContent={"space-around"} >
            <IconButton  onClick={props.on_leave_menu}>
            <FontAwesomeIcon icon={faXmark}/>
            </IconButton>
            <Typography variant="h6">Your Cart</Typography>
            <IconButton size="small">
            <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
            </IconButton>
        </Box>
        {props.cart.items.length === 0
            ? <Typography variant="h6" align="center" mt="40px">Add items to get started</Typography>
            : <>
            <div id='item'>
                <div id='item_title' style={title_style}>
                    <span>item ({props.cart.items.length})</span>
                    <Button onClick={remove_all_items}>Clear All</Button>
                </div>
                <hr style={{ margin: '2px', borderBlockColor: 'black' }}></hr>
                <div id='item_list' style={{ alignContent: 'space-evenly' }}>
                    {
                        props.cart.items.map(item => (
                            <ItemLayout merchandise={item} onRemove={remove_item} />
                        ))
                    }
                </div>
            </div>
            <hr style={{ margin: '2px', borderBlockColor: 'black' }}></hr>
            <div id='summary' style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                <h5>Total price</h5><h5>{props.cart.total_price}BTC</h5>
                </div>
        </>
        }
        <Box display={"flex"} justifyContent={"center"} py={"20px"}>
        <Button variant="contained" size="large" sx={{minWidth:0.9,py:"10px",borderRadius:"12px"}}>Complete purchase</Button>
        </Box>
    </Stack>)
}