import { Box, Button } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'


export interface Wallet {
    name?: string,
    description?: string,
    img_src?: string,
    connect?: () => Promise<void>,
}


const DisplayWallets = (props: { wallets: Wallet[] }) => {

    return (
        <Grid container spacing={5}>
            {props.wallets.map((wallet, index) =>
                <Grid xs={12} md={3} key={index} className="col-lg-3 mb30" onClick={wallet.connect}>
                    <span className="box-url" style={{ minHeight: '18rem' }}>
                        <span className="box-url-label">Most Popular</span>
                        <img src={wallet.img_src} alt="" className="mb20" />
                        <h4>{wallet.name}</h4>
                        <p>{wallet.description}</p>
                    </span>
                </Grid>
            )}
        </Grid>)
}
export default DisplayWallets;
