import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import React, { FC, useState } from "react";
import { z } from "zod";


export enum DayPeriod {
  All = 0,
  OneWeek = 7,
  TwoWeek = 14,
  OneMonth = 30,
  TwoMonth = 60,
}
type LatestDaySelectProps = {
  default_day?: DayPeriod,
  onChange: (selected: DayPeriod) => void,
}

const LatestDaySelect: FC<LatestDaySelectProps> =
  ({ onChange, default_day = DayPeriod.All }) => {
    const days: DayPeriod[] = [0, 7, 14, 30, 60];
    const [selected, set_selected] = useState(default_day);
    const onSelect = (event: SelectChangeEvent<DayPeriod>) => {
      set_selected(z.coerce.number().parse(event.target.value))
      onChange(event.target.value as DayPeriod);
    };
    return <>
      <Select
        sx={{ minWidth: '15%' }}
        value={selected}
        onChange={onSelect}
        MenuProps={{ disableScrollLock: true }}
      >
        {
          days.map((day, index) => (
            <MenuItem key={day} value={day}>
              {day === DayPeriod.All ? `All` : `Last ${day} days`}
            </MenuItem>
          )
          )}
      </Select>
    </>
  }

export default LatestDaySelect;