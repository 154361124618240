
import { Box, Button, Container, Stack, SxProps, Typography } from "@mui/material"
import Paper from "@mui/material/Paper"
import Footer from "../components/footer"
import Grid from '@mui/material/Unstable_Grid2'
import { NavLink } from "react-router-dom"




const Create = () => {

    const block_style:SxProps = {padding: '1rem', textTransform: 'none',borderWidth:"medium",borderStyle:"dashed" };
    return (
        <>
            <Container sx={{ marginTop: '10rem' }}>
                <Typography variant="h4">What are you looking to do?</Typography>
                <Stack marginTop={10} minHeight={'300px'} gap={5}>
                    <NavLink to="/upload">
                    <Button variant="outlined" size="large" fullWidth sx={block_style}>
                        <Stack>
                            <Typography variant="h6">Create Your Idea</Typography>
                            <Typography variant="subtitle2">Upload file to your repository</Typography>
                        </Stack>
                    </Button>
                    </NavLink>
                    <NavLink to="/itemSelect">
                    <Button variant="outlined" size="large" fullWidth sx={block_style}>
                        <Stack>
                            <Typography variant="h6">Mint Ordinal</Typography>
                            <Typography variant="subtitle2">Inscribe your idea to satoshi</Typography>
                        </Stack>
                    </Button>
                    </NavLink>
                    <Stack>
                        <Button variant="outlined" size="large" fullWidth sx={block_style}>
                            <Typography variant="h6">Create Collection</Typography>
                        </Button>
                    </Stack>
                </Stack>
            </Container>
            <Footer />
        </>)
}

export default Create