import React from "react";
import * as MicroStacks from '@micro-stacks/react';
import { UserAccountProvider } from './userAccount';

export const ConnectWallet: React.FC<React.PropsWithChildren> = ({ children }) => {
    return (<>
        <MicroStacks.ClientProvider
            appName="deFintek"
            appIconUrl='/img/logo-3.png'
            network="testnet"
        >
            <UserAccountProvider>
                {children}
            </UserAccountProvider>
        </MicroStacks.ClientProvider>
    </>)


}