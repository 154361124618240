import { useAuth, useMicroStacksClient } from "@micro-stacks/react";
import { createContext, useContext, useState, SetStateAction, useEffect } from "react"
import { AddressPurpose, BitcoinNetworkType, GetAddressOptions, GetAddressResponse, getAddress } from "sats-connect";
import { GetAddressPayload } from "sats-connect";
import { Draft, createSlice } from '@reduxjs/toolkit'
import { z } from "zod";


function useXverse(sate: Draft<{}>, action: { payload: any, type: string }) {
    const { openAuthRequest, isRequestPending, signOut, isSignedIn } = useAuth();

}
const connect_wallet = createSlice({
    name: 'connect_wallet',
    initialState: {},
    reducers: {
        connect_xverse: (state, action) => {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            useXverse(state, action);
        },
        useXverse,
    }
});

export const { connect_xverse } = connect_wallet.actions;
interface StacksSessionState {
    addresses: {
        testnet: string;
        mainnet: string;
    };
    appPrivateKey?: string;
    associationToken?: string;
    hubUrl: string;
    public_keys?: string[];
    profile_url: string;
    username: string | null;
    version?: string;
    decentralizedID?: string;
    identityAddress?: string;
}
// type Para = NonNullable<Parameters<ReturnType<typeof useAuth>['openAuthRequest']>['0']>['onFinish']
// interface MicroSatckAccount {
//     set_user: (session: StacksSessionState) => void,
//     user?: StacksSessionState
// }
// interface SatConnectAccount {
//     set_user: (response: GetAddressResponse) => void,
//     user?: GetAddressResponse
// }




export const address_purpose = z.enum(["ordinals", "payment"]);
const address_schema = z.object({
    address: z.string(),
    publicKey: z.string(),
    purpose: address_purpose,
})
type Address = z.infer<typeof address_schema>;
const net_schema = z.enum(["main", "test"]);
type Net = z.infer<typeof net_schema>;
const account_schema = z.object({
    addresses: z.array(address_schema).default([]),
    net: net_schema.default("main"),
}).nullable();
type Account = z.infer<typeof account_schema>;


interface AccountContext {
    set_net: (net: Net) => void,
    account?: Account,
    connect_wallet: (wallet: Wallet, connect_option?: ConnectOption) => Promise<void>,
    sign_out:()=>void,
    is_signed_out: boolean,
    is_signed_in: boolean
};

const UserAccountContext = createContext<AccountContext>({
    set_net: (net) => { },
    connect_wallet: async (w, o) => { },
    sign_out: () => { },
    is_signed_in: false,
    is_signed_out: true
});
const support_wallet = z.enum(["Xverse", "Stack"]);
type Wallet = z.infer<typeof support_wallet>;

type PlayLoad = GetAddressPayload
interface ConnectOption {
    playload?: PlayLoad,
    onFinish?: (response: any) => void,
    onCancel?: (response: any) => void,
}

const default_account = account_schema.parse({});


// export const is_signed_in = (acct: Account | undefined) => acct?.addresses.length !== 0;
// export const is_signed_out = (acct: Account | undefined) => !is_signed_in(acct);


export const UserAccountProvider: React.FC<React.PropsWithChildren> = ({ children }) => {

    const storage_key = "address";
    const storgae_account = localStorage.getItem(storage_key);
    const [net, set_net] = useState<Net>("main");
    const [account, set_account] = useState<Account>(
        account_schema.parse(storgae_account === null ? null : JSON.parse(storgae_account))
    );



    const { openAuthRequest, isRequestPending, signOut, isSignedIn } = useAuth();
    const sats_address_response2account = (res: GetAddressResponse): Account => {
        return {
            addresses: res.addresses,
            net,
        };
    }
    const getAddressOptions = (
        onFinish?: (acct: Account) => void): GetAddressOptions => {
        return {
            payload: {
                purposes: [AddressPurpose.Ordinals, AddressPurpose.Payment],
                message: 'Address for receiving Ordinals and payments',
                network: {
                    type: net===net_schema.Enum.test? BitcoinNetworkType.Testnet:BitcoinNetworkType.Mainnet
                },
            },
            onFinish: (res) => {
                const acct = sats_address_response2account(res);
                set_account(acct);
                localStorage.setItem(storage_key, JSON.stringify(acct));
                onFinish?.(acct);
            },
            onCancel: () => alert('Request canceled'),
        };
    };
    const sign_out = () => {
        localStorage.removeItem(storage_key);
        set_account(null);
    }
    const connect_wallet = async (wallet: Wallet, connect_option?: ConnectOption) => {
        switch (wallet) {
            case "Xverse":
                await getAddress(getAddressOptions(connect_option?.onFinish))
                break;
            // case "Stack":
            //     console.log("net:");
            //     console.log(net);
            //     console.log("connect stack");
            //     openAuthRequest({ onFinish: () => console.log("stack finish") })
            //     break;
        }

    }
    const is_signed_out = account === null || account.addresses.length ===0;
    const account_context: AccountContext = {
        set_net,
        account,
        is_signed_out,
        is_signed_in: !is_signed_out,
        connect_wallet: connect_wallet,
        sign_out,
    }

    // const [micro_stack_user, set_micor_stack_user] = useState<StacksSessionState>();
    // const [sat_connect_user, set_sat_connect_user] = useState<GetAddressResponse>({ addresses: [] });


    // const user_account: UserAccount = {
    //     micro_stack_account: {
    //         set_user: set_micor_stack_user,
    //         user: micro_stack_user
    //     },
    //     sat_connect_account: {
    //         set_user: set_sat_connect_user,
    //         user: sat_connect_user,
    //     },
    //     user_context: account_context,
    // };
    return <UserAccountContext.Provider value={account_context}>{children}
    </UserAccountContext.Provider>
}

export const useUserAccount = () => useContext(UserAccountContext);
