import { Box, Button, CardActions, Container, Drawer, Tab, Typography } from "@mui/material";
import { Artifact, Material, Ordinal, Print, get_latest_ordinal, get_latest_print } from "../../thirdSystem/merchandise";
import DisplayCard from "../components/DisplayCard";
import GridLayout from "../components/GridLayou";
import { createSearchParams, useNavigate, useSearchParams } from "react-router-dom";
import TabContext from "@mui/lab/TabContext/TabContext";
import TabList from "@mui/lab/TabList/TabList";
import { useEffect, useState } from "react";
import TabPanel from "@mui/lab/TabPanel/TabPanel";
import LatestDaySelect, { DayPeriod } from "../components/LatestDaySelectOption";
import { unstable_batchedUpdates } from "react-dom";


interface SelectPageProps {
  collect: Map<number, string>,
  collect_action: (material: Material) => void,
}
const MatirailList = ({ collect, collect_action: on_collect }: SelectPageProps) => {
  const user_id = "/img/author_single/author_thumbnail.jpg";

  const navigate = useNavigate();
  const to_detail_page = (data: Artifact) => {
    navigate(`/Detail/${data.id}`)
  }

  const to_inscribe_page = (img_id: string) => {
    navigate(`/inscribe?${createSearchParams({ img: img_id })}`);
  }
  const [day_filter, set_day_filter] = useState(DayPeriod.OneWeek);
  const [data, set_data] = useState<Print[]>([]);
  useEffect(() => {
    const data = get_latest_print(user_id, day_filter);
    set_data(data);
  }, [day_filter]);
  const handle_collect = (select: Material) => {
    //console.log("in material collect click");
    console.log(select);
    on_collect(select)
  }
  console.log("In material component");
  return <Box>
    <LatestDaySelect default_day={day_filter} onChange={day => set_day_filter(day)} />
    <GridLayout
      data={data}
      content={data =>
        <DisplayCard
          data={data}
          OnBodyClick={to_detail_page}
          paper_style={{
            sx: collect.has(data.id) ? { borderColor: '#2081e2', borderBlockStyle: 'solid', borderWidth: '0.15rem' } : {}
          }}
          action_bar={data =>
            <CardActions>
              <Box display={"flex"} justifyContent={"space-around"} width={1}>
                <Button variant='text' onClick={e => to_inscribe_page(data.material_src_url)}>
                  Mint
                </Button>
                <Button variant="text" onClick={e => handle_collect(data)}>
                  {collect.has(data.id) ? "remove collect" : "add to collect"}
                </Button>
              </Box>
            </CardActions>} />
      }
    ></GridLayout>
  </Box>
}
const OrdinalList = () => {
  const user_id = "/img/author_single/author_thumbnail.jpg";

  const navigate = useNavigate();
  const to_detail_page = (data: Artifact) => {
    navigate(`/Detail/${data.id}`)
  }
  const to_inscribe_page = (img_id: string) => {
    navigate(`/inscribe?${createSearchParams({ img: img_id })}`);
  }

  const [day_filter, set_day_filter] = useState(DayPeriod.OneWeek);
  const [data, set_data] = useState<Ordinal[]>([]);
  useEffect(() => {
    const data = get_latest_ordinal(user_id, day_filter);
    set_data(data);
  }, [day_filter]);
  return <Box>
    <LatestDaySelect default_day={day_filter} onChange={day => set_day_filter(day)} />
    <GridLayout
      data={data}
      content={data =>
        <DisplayCard
          data={data}
          OnBodyClick={to_detail_page}
          action_bar={data =>
            <CardActions>
              <Button variant='text' onClick={e => to_inscribe_page(data.material_src_url)}>
                Collect
              </Button>
            </CardActions>} />
      }
    ></GridLayout>
  </Box>
}

const ItemSelect = () => {
  //console.log("first in");
  const items = [
    {
      name: "Print",
      display: MatirailList,
      collect: useState<Map<number, string>>(new Map()),
    },
    {
      name: "Ordinal",
      display: OrdinalList,
      collect: useState<Map<number, string>>(new Map()),
    }
  ]

  const [{ name }] = items;

  const [tab_index, set_tab_index] = useState(name);

  const handleChange = (event: React.SyntheticEvent, selected: string) => {
    set_tab_index(selected);
  };

  const add_to_collect = (tab: number, select: number) => {
    // const [collect, set_collect] = items[tab].collect;
    // set_collect(curr => curr.add(select))
    console.log(items[0].collect);
  }
  return <section className="container">
    <Typography variant="h5">Select item you want to add</Typography>
    <TabContext value={tab_index}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <TabList onChange={handleChange} aria-label="lab API tabs example">
          {items.map((item, index) =>
            <Tab label={item.name} key={index} value={item.name} />
          )}
        </TabList>
      </Box>
      {items.map((item, index) => {
        const [collect, set_collect] = item.collect;
        return <TabPanel value={item.name} key={index}>
          {
            item.display({
              collect,
              collect_action: m => {
                set_collect(curr => add_or_remove(curr, m.id, m.material_src_url));
              }
            })}
        </TabPanel>
      })}
    </TabContext>
    <Drawer anchor="bottom" variant="permanent">
      <Box flexDirection={"row"} display={"flex"} justifyContent={"space-around"} maxHeight={"5rem"} padding={"1.2rem"}>
        {items.map((item) => {
          return <>
            {item.name === tab_index
              ? <Box>
                <img src={[...item.collect[0].values()][item.collect[0].size - 1]} alt="" style={{ objectFit: "contain", height: "100%" }}></img>
                {item.collect[0].size !== 0 && item.collect[0].size}
              </Box>
              : <></>}
          </>
        })}
        <Box display={"flex"} gap={"2"} width={"500px"}>
          <Button variant="contained" onClick={e => add_to_collect(0, 0)}>New Collection</Button>
          <Button variant="contained" onClick={e => add_to_collect(1, 1)}>Add To Existing Collect</Button>
        </Box>
      </Box>
    </Drawer>
  </section>
}

function add_or_remove<K, V>(map: Map<K, V>, key: K, value: V) {
  const new_set = new Map<K, V>(map);
  if (map.has(key)) {
    new_set.delete(key);
  }
  else {
    new_set.set(key, value);
  }
  return new_set
}

export default ItemSelect;