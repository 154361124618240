import axios from "axios";
import { NFT } from "../common/merchandise";
import { z } from "zod";
import owned_dummy_data from "../test/nft.json"
import dummyCollection from "../test/hot_collections.json"
import { to_form_data } from "../common/util";


export const inscribe_option_schema = z.object({
    testnet: z.boolean().default(true),
    self_address: z.string().default(''),
    dest_address: z.string().default(''),
    custom_sat: z.boolean().default(true),
    file_location: z.string().default(''),
    fee_rate: z.coerce.number().default(0),
    postage: z.string().default(''),
    txid: z.string().default(''),
    vout: z.string().default(''),
    offset: z.string().default(''),
});
export const InscribeOptionSchema = inscribe_option_schema.keyof()
export type IncribeOption = z.infer<typeof inscribe_option_schema>

export const transaction_schema = z.object({
    commit_tx: z.string(),
    reveal_tx: z.string(),
})
export const InscribeTransactionType = transaction_schema.keyof();
export type InscribeTransactionEnum = z.infer<typeof InscribeTransactionType>;
export type InscribeTransaction = z.infer<typeof transaction_schema>

export const utxo_satoshi_schema = z.object({
    output: z.string().optional(),
    start: z.number(),
    end: z.number(),
    size: z.number().optional(),
    offset: z.number().optional(),
    rarity: z.string().optional(),
    name: z.string().optional(),
})
export type UtxoSatoshi = z.infer<typeof utxo_satoshi_schema>
const domain = 'http://localhost:8080/'
const list_sotoshi_url = domain + 'list';
function ListSotoshi(address: string, testnet: boolean) {
    return axios.post<UtxoSatoshi[]>(list_sotoshi_url, { address, testnet });
}
const inscribe_url = domain + 'inscribe';
function InscribeOrdinal<T>(inscribe_option: IncribeOption) {
    return axios.post<T>(inscribe_url, inscribe_option);
}

function get_nft(id: number): NFT {
    return {
        id: id,
        category: 'NFT',
        img_src: `https://picsum.photos/id/${id}/1920/1920`,
        authorImg: `/img/author/author-${(id) % 12 + 1}.jpg`,
        deadline: `December, ${(id) % 30 + 1}, 2021`,
        authorLink: '#',
        nftLink: '#',
        bidLink: '#',
        price: id,
        title: `title ${id}`,
        bid: `1/${(id) % 50}`,
        likes: id,
        amount: id,
    }
}
function get_nft_list(start: number, end: number): NFT[] {
    return [...Array(end - start).keys()].map(i => ({
        id: i + start,
        category: 'NFT',
        img_src: `https://picsum.photos/id/${i + start}/200/200`,
        authorImg: `/img/author/author-${(i + start) % 12 + 1}.jpg`,
        deadline: `December, ${(i + start) % 30 + 1}, 2021`,
        authorLink: '#',
        nftLink: '#',
        bidLink: '#',
        price: i,
        title: `title ${i + start}`,
        bid: `1/${(i + start) % 50}`,
        likes: i + start,
        amount: i
    })
    )
}
export const material_schema = z.object({
    id: z.number(),
    name: z.string(),
    description: z.string(),
    price: z.coerce.number(),
    material_src_url: z.string(),
    creator: z.string(),
    owner: z.string(),
})
export const category_schema = z.enum(["Ordinal", "Print"])
export type Category = z.infer<typeof category_schema>


export const ordinal_schema = material_schema.extend({
    category: z.literal(category_schema.enum.Ordinal),
}
)
export const print_schema = material_schema.extend({
    category: z.literal(category_schema.enum.Print),
}
)

export const artifact_schema = z.discriminatedUnion("category", [
    ordinal_schema,
    print_schema,
])

export type Material = z.infer<typeof material_schema>
export type Print = z.infer<typeof print_schema>
export type Ordinal = z.infer<typeof ordinal_schema>
export type Artifact = z.infer<typeof artifact_schema>

export const merchandise_transaction_schema = z.object({
    id: z.number(),
    item_id: z.number(),
    type: z.string(),
    sale_price: z.number(),
    from: z.string(),
    to: z.string(),
    date: z.date(),
})
export type Transaction = z.infer<typeof merchandise_transaction_schema>

const item_detail = z.object({
    info: artifact_schema,
    transaction: z.array(merchandise_transaction_schema),
})

export type ItemDetail = z.infer<typeof item_detail>



export const get_owned = (owner_id: string): Artifact[] => {
    return owned_dummy_data.map((data, index) => ({
        category: category_schema.enum.Ordinal,
        id: index,
        name: data.title,
        description: `${index}`,
        price: parseFloat(data.price),
        material_src_url: data.previewImg,
        creator: data.authorImg,
        owner: owner_id,
    }))
}
export const get_onsale = (owner_id: string): Artifact[] => {
    return dummyCollection.map((data, index) => ({
        category: category_schema.enum.Ordinal,
        id: index,
        name: data.username,
        description: `${index}`,
        price: index / 100.0,
        material_src_url: data.banner,
        creator: data.avatar,
        owner: owner_id,
    }))
}
export const get_detail = (owner_id: string, item_id: number): ItemDetail => {
    const item = get_owned(owner_id).find(artifact => artifact.id === item_id)!;
    const fake_description = "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dici enim nihil potest verius. Qui convenit? Sed ea mala virtuti magnitudine obruebantur. Refert tamen, quo modo. At iam decimum annum in spelunca iacet. Frater et T.";
    const dummy_transaction: Transaction[] = [["標售", 10], ["轉移", 0], ["銷售", 0.01], ["標售", 0.01], ["鑄幣", 0]]
        .map((info, index) => {
            const [type, price] = info;
            return {
                id: index,
                item_id,
                type: z.string().parse(type),
                sale_price: z.number().parse(price),
                from: item.creator,
                to: owner_id,
                date: new Date()
            }
        })
    return { info: { ...item, description: fake_description }, transaction: dummy_transaction };
}

export const get_latest_print = (owner_id: string,days:number): Print[] => {
    const data = owned_dummy_data.map((data, index) => ({
        category: category_schema.enum.Print,
        id: index,
        name: data.title,
        description: `${index}`,
        price: parseFloat(data.price),
        material_src_url: data.previewImg,
        creator: data.authorImg,
        owner: owner_id,
    }))
    return days===0?data:data.slice(0,days-5);
}

export const get_latest_ordinal = (owner_id: string,days:number): Ordinal[] => {
    const data = get_onsale(owner_id) as Ordinal[];
    return days===0?data:data.slice(0,days-5);
}

export const print_file_schema = z.object({
    name: z.string().nonempty(),
    description: z.string(),
    creator:z.string(),
    file: z.instanceof(File),
    //extension: z.string().default(""),
})
export type PrintFile = z.infer<typeof print_file_schema>
const upload_print_url = "";
const upload_print = (print: PrintFile) => {
    const form = to_form_data(print);
    return axios.post<number>(upload_print_url, form);
}

export { get_nft_list as GetNFT, ListSotoshi, InscribeOrdinal, get_nft,upload_print }


