import { Outlet, useLocation } from 'react-router-dom';
import { useEffect} from 'react';
import Header from '../menu/header';

export default function Root() {
    const { key } = useLocation();
    useEffect(() => window.scrollTo(0, 0), [key]);
    return (
        <>
            <Header></Header>
            <Outlet />
        </>)
}