import { Component } from "react";
import Clock from "./Clock";
import dummyData from "../../test/nft.json"
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeart } from '@fortawesome/free-solid-svg-icons'




export default class Responsive extends Component {

    constructor(props) {
        super(props);
        this.state = {
            height: 0
        };
        this.onImgLoad = this.onImgLoad.bind(this);
    }

    // loadMore = () => {
    //     let nftState = this.state.nfts
    //     let start = nftState.length
    //     let end = nftState.length + 4
    //     this.setState({
    //         nfts: [...nftState, ...(this.props.dummyData.slice(start, end))]
    //     });
    // }

    onImgLoad({ target: img }) {
        let currentHeight = this.state.height;
        if (currentHeight < img.offsetHeight) {
            this.setState({
                height: img.offsetHeight
            })
        }
    }


    render() {
        return (
            <div className='row'>
                {this.props.dummyData.map((nft, index) => (
                    <div key={index} className="d-item col-lg-4 col-md-6 col-sm-6 col-xs-12 mb-4" onClick={() => this.props.click_detail(nft.id)}>
                        <div className="nft__item m-0">
                            {nft.deadline &&
                                <div className="de_countdown">
                                    <Clock deadline={nft.deadline} />
                                </div>
                            }
                            <div className="author_list_pp">
                                <span onClick={() => window.open(nft.authorLink, "_self")}>
                                    <img className="lazy" src={nft.authorImg} alt="" />
                                    <i className="fa fa-check"></i>
                                </span>
                            </div>
                            <div className="nft__item_wrap" style={{ height: `${this.state.height}px` }}>
                                <span>
                                    <img onLoad={this.onImgLoad} src={nft.img_src} className="lazy nft__item_preview" alt="" />
                                </span>
                            </div>
                            <div className="nft__item_info">
                                <span onClick={() => window.open(nft.nftLink, "_self")}>
                                    <h4>{nft.title}</h4>
                                </span>
                                <div className="nft__item_price">
                                    {nft.price} BTC<span>{nft.bid}</span>
                                </div>
                                <div className="nft__item_action">
                                    <span onClick={() => window.open(nft.bidLink, "_self")}>Place a bid</span>
                                </div>
                                <div style={
                                    {
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignContent: 'center',
                                        alignItems:'center',
                                        marginBottom:'10px'
                                    }
                                }>
                                    <div>
                                        <FontAwesomeIcon icon={faHeart} style={{ color: "#fb8383", }} />{nft.likes}
                                        {/* <i className="fa fa-heart"></i> */}
                                    </div>
                                    <Button onClick={(e) =>{e.stopPropagation(); this.props.buy(nft)}} variant="danger">Buy Now</Button>
                                </div>
                                {/* <i onClick={() => this.props.buy(nft)} className="fa fa-shopping-cart"></i> */}

                            </div>
                        </div>
                    </div>
                ))}
                {/* {this.state.nfts.length !== this.props.dummyData.length &&
                    <div className='col-lg-12'>
                        <div className="spacer-single"></div>
                        <span onClick={() => this.loadMore()} className="btn-main lead m-auto">Load More</span>
                    </div>
                } */}
            </div>
        );
    }
}