import React from "react";
import Clock from "../components/Clock";
import Footer from '../components/footer';
import { createGlobalStyle } from 'styled-components';
import { useParams } from "react-router-dom";
import { Transaction, get_detail, get_nft, merchandise_transaction_schema, transaction_schema } from "../../thirdSystem/merchandise";
import { z } from "zod";
import { Avatar, Box, Card, CardContent, CardMedia, Chip, Container, CssBaselineProps, Paper, Stack, Typography } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { TableCellProps } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';

const GlobalStyles = createGlobalStyle`
  header#myHeader.navbar.white {
    background: #fff;
    border-bottom: solid 1px #dddddd;
  }
  @media only screen and (max-width: 1199px) {
    .navbar{
      background: #403f83;
    }
    .navbar .menu-line, .navbar .menu-line1, .navbar .menu-line2{
      background: #111;
    }
    .item-dropdown .dropdown a{
      color: #111 !important;
    }
  }
`;

type Column<T> = {
  id?: keyof T,
  custom_id?: string,
  label: string,
  align: TableCellProps['align'],
  style: React.CSSProperties,
  value?: (data: T) => React.ReactNode,
}
//type A= Column<Transaction> ;

export const TransactionTable = <T,>(props: { data: T[], columns: Column<T>[] }) => {
  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {props.columns.map((column) => (
                <TableCell
                  key={column.id?.toString() ?? column.custom_id}
                  align={column.align}
                  style={column.style}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.data
              //.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                    {props.columns.map((column) => {
                      return (
                        <TableCell key={column.id?.toString() ?? column.custom_id} align={column.align}>
                          {(column.value?.(row) ?? z.coerce.string().parse(column.custom_id ?? row[column.id as keyof T]))}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      {/* <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      /> */}
    </Paper>
  );
}

export const ItemDetail = () => {
  const user_id = "/img/author_single/author_thumbnail.jpg";

  const { id } = useParams();

  const item = get_detail(user_id, z.coerce.number().parse(id));
  const transaction_history: Column<Transaction>[] = [
    {
      id: 'type',
      align: 'center',
      label: '活動',
      style: { minWidth: '10%' }
    },
    {
      id: 'sale_price',
      align: 'center',
      label: '價格',
      style: { minWidth: '10%' },
      value: (data) => `${data.sale_price} BTC`
    }
    ,
    {
      id: 'from',
      align: 'center',
      label: 'from',
      style: { minWidth: '10%' },
      value: (data) => <Avatar style={{display:'inline-table'}} alt={data.from} src={data.from} ></Avatar>
    }
    ,
    {
      id: 'to',
      align: 'center',
      label: 'to',
      style: { minWidth: '10%' },
      value: (data) =><Avatar style={{display:'inline-table'}} alt={data.to} src={data.to} ></Avatar>
    }
    ,
    {
      id: 'date',
      align: 'center',
      label: 'date',
      style: { minWidth: '10%' },
      value: (data) => data.date.toDateString()
    }
  ]

  return (
    <>
      <GlobalStyles />
      <Container fixed style={{ marginTop: '10rem' }}>
        <Grid container spacing={2} minWidth='100%'>
          <Grid xs={12} md={5} display={'flex'} justifyContent={'center'} height={'100%'}>
            <Paper style={{ minWidth: '100%', display: 'flex', justifyContent: 'center' }}>
              <CardMedia
                component={'img'}
                image={item.info.material_src_url}
                style={{ maxHeight: '100%', height: 'auto', objectFit: 'contain', objectPosition: 'center' }}
              ></CardMedia>
            </Paper>
          </Grid>
          <Grid xs={12} md={7}>
            <CardContent data-nmae="info-content" style={{ height: '100%' }}>
              <Stack direction="column" justifyContent={'space-between'} height={'100%'}>
                <Box>
                  <Typography variant="h3">
                    {item.info.name}
                  </Typography>
                  <Box data-name="chip-bar">
                    <Chip label={item.info.category} />
                  </Box>
                </Box>
                <Typography variant="subtitle1">
                  {item.info.description}
                </Typography>
                <Box>
                  <Typography variant="h6">
                    {item.info.price} BTC
                  </Typography>
                </Box>
              </Stack>
            </CardContent>
          </Grid>
          <Grid xs={12}>
            <Typography variant="h4">
              Details
            </Typography>
            <Paper>

            </Paper>
          </Grid>
          <Grid xs={12}>
            <Typography variant="h4">
              Activity
            </Typography>
            <TransactionTable data={item.transaction}
              columns={transaction_history} />
          </Grid>
        </Grid>
      </Container>
      <Footer />

    </>
  );
}
