import React, { useEffect, useState } from "react";
import Breakpoint, { BreakpointProvider, setDefaultBreakpoints } from "react-socks";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGear, faCartShopping } from '@fortawesome/free-solid-svg-icons'
//import { Link } from '@reach/router';
import useOnclickOutside from "react-cool-onclickoutside";
import {
  NavLink,
} from "react-router-dom";
import { useCart } from "../context/cart";
import { Cart } from "../pages/cart";
import { Avatar, Box, Button, Chip, Drawer } from "@mui/material";
import {  useUserAccount} from '../context/userAccount';


setDefaultBreakpoints([
  { xs: 0 },
  { l: 1199 },
  { xl: 1200 }
]);

const CreateNavLink = props => (
  <NavLink
    {...props}
    className={({ isActive, isPending }) => {
      // the object returned here is passed to the
      // anchor element's props
      return {
        className: isActive ? 'active' : 'non-active',
      };
    }}
  />
);



const Header = function () {

  const cart = useCart();
  const [openMenu, setOpenMenu] = React.useState(false);
  const [openMenu1, setOpenMenu1] = React.useState(false);
  const [openMenu2, setOpenMenu2] = React.useState(false);
  const [openMenu3, setOpenMenu3] = React.useState(false);
  const [openMenu4, setOpenMenu4] = React.useState(false);
  const [open_setting_menu, show_setting_menu] = React.useState(false);
  const [open_nft_menu, show_nft_menu] = React.useState(false);
  const [open_sotoshi_menu, show_sotoshi_menu] = React.useState(false);
  const [open_ip_menu, show_ip_menu] = React.useState(false);
  const [open_cart, show_cart] = React.useState(false);

  const { is_signed_in, account,sign_out } = useUserAccount();
  const address = account?.addresses.find(add => add.purpose === "ordinals")?.address;

  const handleBtnClick = () => {
    setOpenMenu(!openMenu);
  };
  const handleBtnClick1 = () => {
    setOpenMenu1(!openMenu1);
  };
  const handleBtnClick2 = () => {
    setOpenMenu2(!openMenu2);
  };
  const handleBtnClick3 = () => {
    setOpenMenu3(!openMenu3);
  };
  const handleBtnClick4 = () => {
    setOpenMenu4(!openMenu4);
  };

  const closeMenu = () => {
    setOpenMenu(false);
  };
  const closeMenu1 = () => {
    setOpenMenu1(false);
  };
  const closeMenu2 = () => {
    setOpenMenu2(false);
  };
  const closeMenu3 = () => {
    setOpenMenu3(false);
  };
  const closeMenu4 = () => {
    setOpenMenu4(false);
  };
  const ref = useOnclickOutside(() => {
    closeMenu();
  });
  const ref1 = useOnclickOutside(() => {
    closeMenu1();
  });
  const ref2 = useOnclickOutside(() => {
    closeMenu2();
  });
  const ref3 = useOnclickOutside(() => {
    closeMenu3();
  });
  const ref4 = useOnclickOutside(() => {
    closeMenu4();
  });

  const [showmenu, btn_icon] = useState(false);
  useEffect(() => {
    const header = document.getElementById("myHeader");
    const totop = document.getElementById("scroll-to-top");
    const sticky = header.offsetTop;
    const scrollCallBack = window.addEventListener("scroll", () => {
      btn_icon(false);
      if (window.pageYOffset > sticky) {
        header.classList.add("sticky");
        totop.classList.add("show");

      } else {
        header.classList.remove("sticky");
        totop.classList.remove("show");
      } if (window.pageYOffset > sticky) {
        closeMenu();
      }
    });
    return () => {
      window.removeEventListener("scroll", scrollCallBack);
    };
  }, []);
  return (
    <>
      <header id="myHeader" className='navbar white'>
        <div className='container'>
          <div className='w-100-nav' style={{ display: 'flex' }}>
            <div className='logo px-0'>
              <div className='navbar-title navbar-item'>
                <CreateNavLink to="/">
                  <img
                    src="/img/logo.png"
                    className="img-fluid d-block"
                    alt="#"
                  />
                  <img
                    src="/img/logo-2.png"
                    className="img-fluid d-3"
                    alt="#"
                  />
                  <img
                    src="/img/logo-light.png"
                    className="img-fluid d-none"
                    alt="#"
                  />
                </CreateNavLink>
              </div>
            </div>

            <div className='search'>
              <input id="quick_search" className="xs-hide" name="quick_search" placeholder="search item here..." type="text" />
            </div>

            <BreakpointProvider>
              <Breakpoint l down>
                {showmenu &&
                  <div className='menu'>
                    <div className='navbar-item'>
                      <div ref={ref}>
                        <div className="dropdown-custom dropdown-toggle btn"
                          onClick={handleBtnClick}
                        >
                          Home
                        </div>
                        {openMenu && (
                          <div className='item-dropdown'>
                            <div className="dropdown" onClick={closeMenu}>
                              <CreateNavLink to=" " onClick={() => window.open("http://gigaland.grey.on3-step.com", "_self")}>New Grey Scheme</CreateNavLink>
                              <CreateNavLink to=" " onClick={() => window.open("http://gigaland.retro.on3-step.com", "_self")}>New Retro Scheme</CreateNavLink>
                              <CreateNavLink to="/" onClick={() => btn_icon(!showmenu)}>Homepage</CreateNavLink>
                              <CreateNavLink to="/home1" onClick={() => btn_icon(!showmenu)}>Homepage 1</CreateNavLink>
                              <CreateNavLink to="/home2" onClick={() => btn_icon(!showmenu)}>Homepage 2</CreateNavLink>
                              <CreateNavLink to="/home3" onClick={() => btn_icon(!showmenu)}>Homepage 3</CreateNavLink>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className='navbar-item'>
                      <div ref={ref1}>
                        <div className="dropdown-custom dropdown-toggle btn"
                          onClick={handleBtnClick1}
                        >
                          Explore
                        </div>
                        {openMenu1 && (
                          <div className='item-dropdown'>
                            <div className="dropdown" onClick={closeMenu1}>
                              <CreateNavLink to="/explore" onClick={() => btn_icon(!showmenu)}>Explore</CreateNavLink>
                              <CreateNavLink to="/explore2" onClick={() => btn_icon(!showmenu)}>Explore 2</CreateNavLink>
                              <CreateNavLink to="/rangking" onClick={() => btn_icon(!showmenu)}>Rangking</CreateNavLink>
                              <CreateNavLink to="/colection" onClick={() => btn_icon(!showmenu)}>Collection</CreateNavLink>
                              <CreateNavLink to="/ItemDetail" onClick={() => btn_icon(!showmenu)}>Items Details</CreateNavLink>
                              <CreateNavLink to="/Auction" onClick={() => btn_icon(!showmenu)}>Live Auction</CreateNavLink>
                              <CreateNavLink to="/helpcenter" onClick={() => btn_icon(!showmenu)}>Help Center</CreateNavLink>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className='navbar-item'>
                      <div ref={ref2}>
                        <div className="dropdown-custom dropdown-toggle btn"
                          onClick={handleBtnClick2}
                        >
                          Pages
                        </div>
                        {openMenu2 && (
                          <div className='item-dropdown'>
                            <div className="dropdown" onClick={closeMenu2}>
                              <CreateNavLink to="/Author" onClick={() => btn_icon(!showmenu)}>Author</CreateNavLink>
                              <CreateNavLink to="/wallet" onClick={() => btn_icon(!showmenu)}>Wallet</CreateNavLink>
                              <CreateNavLink to="/create" onClick={() => btn_icon(!showmenu)}>Create</CreateNavLink>
                              <CreateNavLink to="/create2" onClick={() => btn_icon(!showmenu)}>Create 2</CreateNavLink>
                              <CreateNavLink to="/createOptions" onClick={() => btn_icon(!showmenu)}>Create options</CreateNavLink>
                              <CreateNavLink to="/news" onClick={() => btn_icon(!showmenu)}>News</CreateNavLink>
                              <CreateNavLink to="/works" onClick={() => btn_icon(!showmenu)}>Gallery</CreateNavLink>
                              <CreateNavLink to="/login" onClick={() => btn_icon(!showmenu)}>login</CreateNavLink>
                              <CreateNavLink to="/loginTwo" onClick={() => btn_icon(!showmenu)}>login 2</CreateNavLink>
                              <CreateNavLink to="/register" onClick={() => btn_icon(!showmenu)}>Register</CreateNavLink>
                              <CreateNavLink to="/contact" onClick={() => btn_icon(!showmenu)}>Contact Us</CreateNavLink>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className='navbar-item'>
                      <CreateNavLink to="/activity" onClick={() => btn_icon(!showmenu)}>
                        Activity
                      </CreateNavLink>
                    </div>
                    <div className='navbar-item'>
                      <div ref={ref3}>
                        <div className="dropdown-custom dropdown-toggle btn"
                          onClick={handleBtnClick3}
                        >
                          Element
                        </div>
                        {openMenu3 && (
                          <div className='item-dropdown'>
                            <div className="dropdown" onClick={closeMenu3}>
                              <CreateNavLink to="/elegantIcons" onClick={() => btn_icon(!showmenu)}>Elegant Icon</CreateNavLink>
                              <CreateNavLink to="/etlineIcons" onClick={() => btn_icon(!showmenu)}>Etline Icon</CreateNavLink>
                              <CreateNavLink to="/fontAwesomeIcons" onClick={() => btn_icon(!showmenu)}>Font Awesome Icon</CreateNavLink>
                              <CreateNavLink to="/accordion" onClick={() => btn_icon(!showmenu)}>Accordion</CreateNavLink>
                              <CreateNavLink to="/alerts" onClick={() => btn_icon(!showmenu)}>Alerts</CreateNavLink>
                              <CreateNavLink to="/price" onClick={() => btn_icon(!showmenu)}>Pricing Table</CreateNavLink>
                              <CreateNavLink to="/progressbar" onClick={() => btn_icon(!showmenu)}>Progress bar</CreateNavLink>
                              <CreateNavLink to="/tabs" onClick={() => btn_icon(!showmenu)}>Tabs</CreateNavLink>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className='navbar-item'>
                      <CreateNavLink to="/test">
                        Test
                        <span className='lines'></span>
                      </CreateNavLink>
                    </div>
                    <div className='navbar-item'>
                      <div ref={ref4}>
                        <div className="dropdown-custom dropdown-toggle btn"
                          onClick={handleBtnClick4}
                        >
                          Reference
                        </div>
                        {openMenu4 && (
                          <div className='item-dropdown'>
                            <div className="dropdown" onClick={closeMenu4}>
                              <CreateNavLink to="/explore" onClick={() => btn_icon(!showmenu)}>Explore</CreateNavLink>
                              <CreateNavLink to="/explore2" onClick={() => btn_icon(!showmenu)}>Explore 2</CreateNavLink>
                              <CreateNavLink to="/rangking" onClick={() => btn_icon(!showmenu)}>Rangking</CreateNavLink>
                              <CreateNavLink to="/colection" onClick={() => btn_icon(!showmenu)}>Collection</CreateNavLink>
                              <CreateNavLink to="/ItemDetail" onClick={() => btn_icon(!showmenu)}>Items Details</CreateNavLink>
                              <CreateNavLink to="/Auction" onClick={() => btn_icon(!showmenu)}>Live Auction</CreateNavLink>
                              <CreateNavLink to="/helpcenter" onClick={() => btn_icon(!showmenu)}>Help Center</CreateNavLink>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                }
              </Breakpoint>

              <Breakpoint xl>
                <div className='menu'>
                  <div className='navbar-item'>
                    <CreateNavLink to="/test">
                      Test
                      <span className='lines'></span>
                    </CreateNavLink>
                  </div>
                  {/* <div className='navbar-item'>
                    <div ref={ref4}>
                      <div className="dropdown-custom dropdown-toggle btn"
                        onMouseEnter={handleBtnClick4} onMouseLeave={closeMenu4}>
                        Reference
                        <span className='lines'></span>
                        {openMenu4 && (
                          <div className='item-dropdown'>
                            <div className="dropdown" onClick={closeMenu4}>
                              <CreateNavLink to=" " onClick={() => window.open("http://gigaland.grey.on3-step.com", "_self")}>New Grey Scheme</CreateNavLink>
                              <CreateNavLink to=" " onClick={() => window.open("http://gigaland.retro.on3-step.com", "_self")}>New Retro Scheme</CreateNavLink>
                              <CreateNavLink to="/">Homepage</CreateNavLink>
                              <CreateNavLink to="/home1">Homepage 1</CreateNavLink>
                              <CreateNavLink to="/home2">Homepage 2</CreateNavLink>
                              <CreateNavLink to="/homeDefault">Homepage 3</CreateNavLink>
                              <CreateNavLink to="/wallet">Wallet</CreateNavLink>
                              <CreateNavLink to="/create">Create</CreateNavLink>
                              <CreateNavLink to="/create2">Create 2</CreateNavLink>
                              <CreateNavLink to="/create3">Create 3</CreateNavLink>
                              <CreateNavLink to="/createOptions">Create Option</CreateNavLink>
                              <CreateNavLink to="/news">News</CreateNavLink>
                              <CreateNavLink to="/works">Gallery</CreateNavLink>
                              <CreateNavLink to="/login">login</CreateNavLink>
                              <CreateNavLink to="/loginTwo">login 2</CreateNavLink>
                              <CreateNavLink to="/register">Register</CreateNavLink>
                              <CreateNavLink to="/contact">Contact Us</CreateNavLink>
                              <CreateNavLink to="/colection">Collection</CreateNavLink>
                              <CreateNavLink to="/ItemDetail">Items Details</CreateNavLink>
                              <CreateNavLink to="/Auction">Live Auction</CreateNavLink>
                              <CreateNavLink to="/helpcenter">Help Center</CreateNavLink>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className='navbar-item'>
                    <div ref={ref3}>
                      <div className="dropdown-custom dropdown-toggle btn"
                        onMouseEnter={handleBtnClick3} onMouseLeave={closeMenu3}>
                        Elements
                        <span className='lines'></span>
                        {openMenu3 && (
                          <div className='item-dropdown'>
                            <div className="dropdown" onClick={closeMenu3}>
                              <CreateNavLink to="/elegantIcons">Elegant Icon</CreateNavLink>
                              <CreateNavLink to="/etlineIcons">Etline Icon</CreateNavLink>
                              <CreateNavLink to="/fontAwesomeIcons">Font Awesome Icon</CreateNavLink>
                              <CreateNavLink to="/accordion">Accordion</CreateNavLink>
                              <CreateNavLink to="/alerts">Alerts</CreateNavLink>
                              <CreateNavLink to="/price">Pricing Table</CreateNavLink>
                              <CreateNavLink to="/progressbar">Progess Bar</CreateNavLink>
                              <CreateNavLink to="/tabs">Tabs</CreateNavLink>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div> */}
                  <div className='navbar-item'>
                    <CreateNavLink to="/"   >
                      Home
                      <span className='lines'></span>
                    </CreateNavLink>
                  </div>
                  <div className='navbar-item'>
                    <div ref={ref1}>
                      <div className="dropdown-custom dropdown-toggle btn"
                        onMouseEnter={handleBtnClick1} onMouseLeave={closeMenu1}>
                        Explore
                        <span className='lines'></span>
                        {openMenu1 && (
                          <div className='item-dropdown'>
                            <div className="dropdown" onClick={closeMenu1}>
                              <CreateNavLink to="/explore">Explore</CreateNavLink>
                              <CreateNavLink to="/explore1">Explore 2</CreateNavLink>
                              <CreateNavLink to="/Author">Explore Author</CreateNavLink>
                              <CreateNavLink to="/rangking">Rangking</CreateNavLink>
                              <CreateNavLink to="/activity">Activity</CreateNavLink>
                              <CreateNavLink to="/create2">Pricing Table</CreateNavLink>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className='navbar-item'>
                    <div className="dropdown-custom btn"
                      onMouseEnter={() => show_nft_menu(true)} onMouseLeave={() => show_nft_menu(false)}>
                      <CreateNavLink to="/explore/NFT"   >
                        Ordinal
                      </CreateNavLink>
                      {/* <span className='lines'></span>
                      {open_nft_menu && (
                        <div className='item-dropdown'>
                          <div className="dropdown" onClick={closeMenu1}>
                            <CreateNavLink to="/createOptions">CreateNFT</CreateNavLink>
                            <CreateNavLink to="/explore/NFT">All NFT</CreateNavLink>
                          </div>
                        </div>
                      )} */}
                    </div>
                  </div>
                  <div className='navbar-item'>
                    <div className="dropdown-custom btn"
                      onMouseEnter={() => show_sotoshi_menu(true)} onMouseLeave={() => show_sotoshi_menu(false)}>
                        Sotoshi
                      {/* <span className='lines'></span>
                      {open_sotoshi_menu && (
                        <div className='item-dropdown'>
                          <div className="dropdown" onClick={closeMenu1}>
                            <CreateNavLink to="/createOptions">Select Satoshi</CreateNavLink>
                            <CreateNavLink to="/explore/sotoshi">All Satoshi</CreateNavLink>
                          </div>
                        </div>
                      )} */}
                    </div>
                  </div>
                  <div className='navbar-item'>
                    <div className="dropdown-custom btn"
                      onMouseEnter={() => show_ip_menu(true)} onMouseLeave={() => show_ip_menu(false)}>
                      <CreateNavLink to="/explore/ip"   >
                        IP
                      </CreateNavLink>
                      {/* <span className='lines'></span>
                      {open_ip_menu && (
                        <div className='item-dropdown'>
                          <div className="dropdown" onClick={closeMenu1}>
                            <CreateNavLink to="/createOptions">Mint IP</CreateNavLink>
                            <CreateNavLink to="/explore/NFT">All IP</CreateNavLink>
                          </div>
                        </div>
                      )} */}
                    </div>
                  </div>
                  <div className='navbar-item'>
                    <div className="dropdown-custom btn">
                      <CreateNavLink to="CreatePanel">
                        Create
                      </CreateNavLink>
                      <span className='lines'></span>
                    </div>
                  </div>
                  {/* <div className='navbar-item'>
                    <CreateNavLink to="/createOptions"   >
                      Create
                      <span className='lines'></span>
                    </CreateNavLink>
                  </div> */}
                </div>
              </Breakpoint>
            </BreakpointProvider>            
            <div className='mainside'>
              {is_signed_in
                ? <Chip
                  avatar = {
                    < Avatar alt = "User" src = "/img/author/author-11.jpg"/>
                  }
                  label={address}
                  clickable
                  sx={{ overflow: "hidden", width: "100px" }} />
                : <CreateNavLink to="/wallet" className="btn-main"> Connect Wallet </CreateNavLink>
            }
            </div>
            <div className='navbar-item'>
              <div>
                <div className="dropdown-custom dropdown-toggle btn"
                  onMouseEnter={() => show_setting_menu(true)} onMouseLeave={() => show_setting_menu(false)}>
                  <FontAwesomeIcon icon={faGear} />
                  <span className='lines'></span>
                  {open_setting_menu && (
                    <div className='item-dropdown'>
                      <div className="dropdown" onClick={closeMenu1}>
                        <CreateNavLink to="/Profile">Profile</CreateNavLink>
                        <CreateNavLink to="/helpcenter">Help Center</CreateNavLink>
                        <Box display={"flex"}px="10px">
                        <Button onClick={sign_out}>Log out</Button>
                        </Box>
                        {/* <CreateNavLink to="/login">Log out</CreateNavLink> */}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
                        <div className='navbar-item'>
              <div className="dropdown-custom dropdown-toggle btn"
                  onMouseEnter={() => show_cart(true)}>
              <CreateNavLink to="/activity">
                <FontAwesomeIcon icon={faCartShopping} />
                {cart.total_item}
                <span className='lines'></span>
              </CreateNavLink>
              {
(< Drawer open={open_cart} anchor="right" onClose={e=>show_cart(false)}>
                    < Cart cart={
                    cart
                  }
                      on_leave_menu={()=>show_cart(false)}
                  /></Drawer > )
              }
              </div>
            </div>
          </div>
          <button className="nav-icon" onClick={() => btn_icon(!showmenu)}>
            <div className="menu-line white"></div>
            <div className="menu-line1 white"></div>
            <div className="menu-line2 white"></div>
          </button>

        </div>
      </header>
    </>
  );
}
export default Header;