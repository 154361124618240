import React, { MouseEvent, MouseEventHandler, ReactNode, useState } from "react";
import ColumnZero from '../components/ColumnZero';
import ColumnZeroTwo from '../components/ColumnZeroTwo';
import ColumnZeroThree from '../components/ColumnZeroThree';
import Footer from '../components/footer';
import { createGlobalStyle } from 'styled-components';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { RouteObject, createSearchParams, useNavigate } from "react-router-dom";
import { Button, Card, CardActionArea, CardActions, CardContent, CardMedia, Chip, MenuItem, Paper, Select, Typography } from "@mui/material";

import { Artifact, Category, category_schema, get_onsale, get_owned } from "../../thirdSystem/merchandise";
import { z } from "zod";
import GridLayout from "../components/GridLayou";
import DisplayCard from "../components/DisplayCard";
import LatestDaySelect from "../components/LatestDaySelectOption";


const GlobalStyles = createGlobalStyle`
  header#myHeader.navbar.white {
    background: #fff;
  }
  @media only screen and (max-width: 1199px) {
    .navbar{
      background: #403f83;
    }
    .navbar .menu-line, .navbar .menu-line1, .navbar .menu-line2{
      background: #111;
    }
    .item-dropdown .dropdown a{
      color: #111 !important;
    }
  }
`;


const CategorySelect = () => {
  const categories = [category_schema.enum.Ordinal, category_schema.enum.Print];
  const [selected, set_selected] = useState<Category>(category_schema.enum.Ordinal);
  return <>
    <Select
      sx={{ minWidth: '15%' }}
      value={selected}
      onChange={(event) => set_selected(category_schema.parse(event.target.value))}
      MenuProps={{ disableScrollLock: true }}
    >
      {
        categories.map((category, index) => (
          <MenuItem key={category} value={category}>
            {category}
          </MenuItem>
        )
        )}
    </Select>
  </>
}

const Owned = () => {
  const user_id = "/img/author_single/author_thumbnail.jpg";
  const owned_item = get_owned(user_id);
  const navigate = useNavigate();
  const to_detail_page = (data:Artifact)=>{
    navigate(`/Detail/${data.id}`);
  }
  const to_sale_page = (data:Artifact)=>{
    navigate(`/sale/${data.id}`);
  }
  return <>
    <Box sx={{ paddingBottom: '2rem', display: 'flex', gap: '1rem' }}>
      <CategorySelect />
      <LatestDaySelect onChange={date=>date} />
    </Box>
    <GridLayout
      data={owned_item}
      content={data =>
        <>
          <DisplayCard 
          data={data} 
          OnBodyClick={to_detail_page}
          action_bar={data =>
            <CardActions>
              <Button variant='text' onClick={e=>to_sale_page(data)}>
                List for sale
              </Button>
            </CardActions>} />
        </>}
    />
  </>
}
const OnSale = () => {
  const user_id = "./img/author_single/author_thumbnail.jpg";
  const owned_item = get_onsale(user_id);
  const navigate = useNavigate();
  const to_detail_page = (data:Artifact)=>{
    navigate(`/Detail/${data.id}`)

  }
  return <>
    <GridLayout
      data={owned_item}
      content={data =>
        <>
          <DisplayCard 
          data={data} 
          OnBodyClick={to_detail_page}
          action_bar={data =>
            <></>
          } />
        </>}
    />
  </>
}
interface Function {
  name: string,
  element: ReactNode,
}
const function_list: Function[] = [
  {
    name: 'Owned',
    element: <Owned />
  },
  {
    name: 'On Sale',
    element: <OnSale />
  },
  {
    name: 'Created',
    element: <Owned />
  },
  {
    name: 'Collection',
    element: <Owned />
  }, {
    name: 'Favorited',
    element: <Owned />
  },
]
const Profile = () => {
  const [{ name }] = function_list;
  const [tab_index, set_tab_index] = React.useState(name);

  const handleChange = (event: React.SyntheticEvent, selected: string) => {
    set_tab_index(selected);
  };
  return (
    <div>
      <GlobalStyles />

      <section id='profile_banner' className='jumbotron breadcumb no-bg' style={{ backgroundImage: `url(${'./img/author_single/author_banner.jpg'})` }}>
        <div className='mainbreadcumb'>
        </div>
      </section>

      <section className='container no-bottom'>
        <div className='row'>
          <div className="col-md-12">
            <div className="d_profile de-flex">
              <div className="de-flex-col">
                <div className="profile_avatar">
                  <img src="./img/author_single/author_thumbnail.jpg" alt="" />
                  <i className="fa fa-check"></i>
                  <div className="profile_name">
                    <h4>
                      Monica Lucas
                      <span className="profile_username">@monicaaa</span>
                      <span id="wallet" className="profile_wallet">DdzFFzCqrhshMSxb9oW3mRo4MJrQkusV3fGFSTwaiu4wPBqMryA9DYVJCkW9n7twCffG5f5wX2sSkoDXGiZB1HPa7K7f865Kk4LqnrME</span>
                      <button id="btn_copy" title="Copy Text">Copy</button>
                    </h4>
                  </div>
                </div>
              </div>
              <div className="profile_follow de-flex">
                <div className="de-flex-col">
                  <div className="profile_follower">500 followers</div>
                </div>
                {/* <div className="de-flex-col">
                  <span className="btn-main">Follow</span>
                </div> */}
              </div>

            </div>
          </div>
        </div>
      </section>

      <section className='container no-top'>
        <Box sx={{ width: '100%', typography: (theme) => theme.typography.body1 }}>
          <TabContext value={tab_index}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleChange} aria-label="lab API tabs example">
                {function_list.map((func, index) =>
                  <Tab label={func.name} key={index} value={func.name} />
                )}
              </TabList>
            </Box>
            {function_list.map((func, index) => (
              <TabPanel value={func.name} key={index}>{func.element}</TabPanel>
            ))}
          </TabContext>
        </Box>
      </section>


      <Footer />
    </div>
  );
}
export const ProfileRoots: RouteObject = {
  path: 'Profile',
  element: <Profile />,
  children: [],
}
export default Profile;