import Clock from "../components/Clock";
import Footer from '../components/footer';
import { createGlobalStyle } from 'styled-components';
import ImageUploading, { ImageListType, ImageType } from 'react-images-uploading';
import React, {  useEffect, useRef, useState } from "react";
import { z } from "zod";
import { HandleInput, } from "../../common/util";
import { TextareaAutosize } from "@mui/base";
import { Button } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2'
import {  useNavigate } from "react-router-dom";
import { PrintFile, print_file_schema,  upload_print } from "../../thirdSystem/merchandise";
import { useUserAccount } from "../context/userAccount";


const GlobalStyles = createGlobalStyle`
  @media only screen and (max-width: 1199px) {
    .navbar{
      background: #403f83;
    }
    .navbar .menu-line, .navbar .menu-line1, .navbar .menu-line2{
      background: #fff;
    }
    .item-dropdown .dropdown a{
      color: #fff !important;
    }
  }
`;

const file_info = print_file_schema.omit({
    creator: true,
})
const partial_file_info = file_info.partial();
const file_info_property = file_info.keyof();

type FileInfo = z.infer<typeof file_info>;
type PartialFileInfo = z.infer<typeof partial_file_info>;

function parse_to_file(upload: ImageType): PartialFileInfo {
    return {
        name: upload.file?.name,
        file: upload.file,
        description: upload.file?.type,
        //extension: upload.file?.type,
    }
}
interface UploadPageProps {
    onFinished: (print: FileInfo) => void,
}
const UploadPage = ({ onFinished }: UploadPageProps) => {

    const img_url_key = 'img_url';
    const default_img: ImageType = { [img_url_key]: '' };

    const [img_files, set_img_files] = useState<ImageListType>([]);
    const [print, set_print] = useState<PartialFileInfo>(partial_file_info.parse({}));
    const set_file = (image_list: ImageListType, add_update_index: number[] | undefined) => {
        set_img_files(image_list)
        const file = parse_to_file(image_list[0]);
        set_print(curr => ({ ...curr, ...file }));
    };
    const alter_file_description = HandleInput<PrintFile>((name, value) => {
        set_print(curr => ({ ...curr, [name]: value }))
    });

    const [disable, disable_continue] = useState(true);

    let print_file = useRef<FileInfo>();

    useEffect(() => {
        try {
            print_file.current = file_info.parse(print);
            disable_continue(false);
        }
        catch {
            disable_continue(true);
        }
    }, [print])


    //  const reader = new FileReader()
    // reader.addEventListener(
    //     "load",
    //     () => {
    //     console.log(reader.result);
    //     },
    //     true 
    // )
    //     reader.readAsDataURL(print.file ?? new File([], "no file"));

    return (
        <div>
            <GlobalStyles />
            <section className='container'>
                <div className="row">
                    <div className="col-lg-7 offset-lg-1 mb-5">
                        <form id="form-create-item" className="form-border" action="#">
                            <div className="field-set">
                                <h5>Upload file</h5>
                                <ImageUploading
                                    value={img_files}
                                    dataURLKey={img_url_key}
                                    onChange={set_file}
                                >
                                    {({ imageList, onImageUpload, onImageRemoveAll, isDragging, dragProps }) => (
                                        <div className="d-create-file" {...dragProps} onClick={onImageUpload} style={{ cursor: 'pointer' }}>
                                            <p>Click or Drop To Upload file</p>
                                            <p id="file_name">PNG, JPG, GIF, WEBP or MP4. Max 200mb.</p>
                                            {imageList.map((image, index) => (
                                                <>
                                                    <img src={image[img_url_key]} alt="" key={index} style={{ maxWidth: '100%', objectFit: 'scale-down' }}></img>
                                                </>
                                            ))}

                                        </div>
                                    )}
                                </ImageUploading>

                                <div className="spacer-single"></div>

                                <h5>Title</h5>
                                <input type="text" name={file_info_property.enum.name} onInput={alter_file_description} id="item_title" className="form-control" placeholder="e.g. 'Crypto Funk" value={print.name} />
                                <div className="spacer-10"></div>

                                <h5>Description</h5>
                                <TextareaAutosize name={file_info_property.enum.description} onInput={alter_file_description} id="item_desc" className="form-control" placeholder="e.g. 'This is very limited item'"></TextareaAutosize>
                                <div className="spacer-10"></div>

                                <h5>Select Category</h5>
                                <input type="text" name="item_price" id="item_price" className="form-control" placeholder="e.g. Galaxy" />

                                <div className="spacer-10"></div>
                            </div>
                            <Button size="large" variant="contained" disabled={disable} onClick={e => onFinished(print_file.current!)}>Upload</Button>
                        </form>
                    </div>

                    <div className="col-lg-3 col-sm-6 col-xs-12">
                        <h5>Preview item</h5>
                        <div className="nft__item m-0">
                            <div className="de_countdown">
                                <Clock deadline="December, 30, 2021" />
                            </div>
                            <div className="author_list_pp">
                                <span>
                                    <img className="lazy" src="./img/author/author-1.jpg" alt="" />
                                    <i className="fa fa-check"></i>
                                </span>
                            </div>
                            <div className="nft__item_wrap">
                                <span>
                                    <img src={(img_files[0] ?? default_img)[img_url_key]} id="get_file_2" className="lazy nft__item_preview" alt="" />
                                </span>
                            </div>
                            <div className="nft__item_info">
                                <span >
                                    <h4>{print.name}</h4>
                                </span>
                                <div className="nft__item_price">
                                    0.00 BTC
                                </div>
                                <div className="nft__item_action">
                                    <span>Place a bid</span>
                                </div>
                                <div className="nft__item_like">
                                    <i className="fa fa-heart"></i><span>50</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    );
}



export const UploadFile = () => {
    // const { account, is_signed_in } = useUserAccount();
    // const navigate = useNavigate();
    // const location = useLocation();
    // console.log(is_signed_in);
    // useEffect
    // if (!is_signed_in) {
    //     navigate(`/wallet${location.pathname}`);
    // }

    const { account, } = useUserAccount();
    const navigate = useNavigate();
    
    const to_create_panel = () => {
        const result_description = "✨Congratulations on completing your creation!";
        navigate(`/CreatePanel/${result_description}`);
    }
    const on_finished = (print: PartialFileInfo) => {
        upload_print(print_file_schema.parse({
            ...print,
            creator: account?.addresses.find(add => add.purpose === "ordinals")?.address!
        }))
            .then(_ => to_create_panel())
            .catch(err => console.log(err));
    }
    return (<UploadPage onFinished={on_finished} />)

}