import DisplayWallets, { Wallet } from '../components/wallet';
import Footer from '../components/footer';
import { createGlobalStyle } from 'styled-components';
import { useUserAccount } from '../context/userAccount';
import { useLocation, useNavigate, useParams } from "react-router";
const GlobalStyles = createGlobalStyle`
  header#myHeader.navbar.sticky.white {
    background: #403f83;
    border-bottom: solid 1px #403f83;
  }
  header#myHeader.navbar .search #quick_search{
    color: #fff;
    background: rgba(255, 255, 255, .1);
  }
  header#myHeader.navbar.white .btn, .navbar.white a, .navbar.sticky.white a{
    color: #fff;
  }
  header#myHeader .dropdown-toggle::after{
    color: rgba(255, 255, 255, .5);
  }
  header#myHeader .logo .d-block{
    display: none !important;
  }
  header#myHeader .logo .d-none{
    display: block !important;
  }
  @media only screen and (max-width: 1199px) {
    .navbar{
      background: #403f83;
    }
    .navbar .menu-line, .navbar .menu-line1, .navbar .menu-line2{
      background: #fff;
    }
    .item-dropdown .dropdown a{
      color: #fff !important;
    }
  }
`;

const WalletLayout = () => {

  const { connect_wallet } = useUserAccount();
  const navigate = useNavigate();

  const { from } = useParams();
  const redirect_to_prev_page = () => {
    navigate(`/${from??""}`)
  }

  const supportWallets: Wallet[] = [
    {
      name: 'Xverse',
      description: 'Sats Connect',
      img_src: "/img/wallet/xverse.png",
      connect: () => connect_wallet("Xverse", { onFinish: redirect_to_prev_page }),
    },
    {
      name: 'Hero Leather',
      description: 'Hero',
      img_src: "/img/wallet/leather.jpg",
    },
    {
      name: 'WalletConnect',
      description: 'Open source protocol for connecting decentralised applications to mobile wallets.',
      img_src: "/img/wallet/4.png",
    }
  ]
  return (
    <div>
      <GlobalStyles />
      <section className='jumbotron breadcumb no-bg' style={{ backgroundImage: "/img/background/subheader.jpg" }}>
        <div className='mainbreadcumb'>
          <div className='container'>
            <div className='row m-10-hor'>
              <div className='col-12'>
                <h1 className='text-center'>Wallet</h1>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='container'>
        <DisplayWallets wallets={supportWallets} />
      </section>

      <Footer />
    </div>

  );
}



export default WalletLayout;