import React from 'react';
import { createBrowserRouter, RouterProvider, } from "react-router-dom";
import ScrollToTopBtn from './menu/ScrollToTop';
import HomeDefault from './pages/home_default';
import Home1 from './pages/home1';
import Home2 from './pages/home2';
import Home from './pages/home';
import Explore1 from './pages/explore1';
import Explore from './pages/explore';
import Rangking from './pages/rangking';
import Auction from './pages/Auction';
import Helpcenter from './pages/helpcenter';
import Colection from './pages/colection';
import ItemDetail from './pages/ItemDetail';
import Author from './pages/Author';
import WalletLayout from './pages/walletLayout';
import Login from './pages/login';
import LoginTwo from './pages/loginTwo';
import Register from './pages/register';
import Price from './pages/price';
import Works from './pages/works';
import News from './pages/news';
import Create1 from './pages/create1';
import Create2 from './pages/create2';
import Create3 from './pages/create3';
import Createoption from './pages/createOptions';
import Activity from './pages/activity';
import Contact from './pages/contact';
import ElegantIcons from './pages/elegantIcons';
import EtlineIcons from './pages/etlineIcons';
import FontAwesomeIcons from './pages/fontAwesomeIcons';
import Accordion from './pages/accordion';
import Alerts from './pages/alerts';
import Progressbar from './pages/progressbar';
import { ItemDetail as Detail } from './pages/ArtifactDetail';
import Tabs from './pages/tabs';
import Test from '../test/test';

import { createGlobalStyle } from 'styled-components';
import Root from './pages/root';
import ExploreNFT from './pages/exploreNFT';
import ExploreSotoshi from './pages/exploreSatoshi';
import { ConnectWallet } from './context/connectWallet';
import { CartProvider } from './context/cart';
import { Mint } from './pages/mint';
import Profile, { ProfileRoots } from './pages/profile';
import Create from './pages/Create';
import ItemSelect from './pages/itemSelect';
import { UploadFile } from './pages/uploadFile';
import CreatePanel from './pages/createPanel';
import Inscribe from './pages/inscribe';
import Sale from './pages/sale';

const GlobalStyles = createGlobalStyle`
  :root {
    scroll-behavior: unset;
  }
`;

export const ScrollTop = ({ children, location }: { children: any, location: any }) => {
  React.useEffect(() => window.scrollTo(0, 0), [location])
  return children
}
const router = createRouter();

const App = () => {
  return (
    <ConnectWallet>
      <CartProvider>
        <div className="wraper">
          <GlobalStyles />
          <ScrollTop location="/">
            <RouterProvider router={router} />
          </ScrollTop>
          <ScrollToTopBtn />
        </div>
      </CartProvider>
    </ConnectWallet>

  );
}

function createRouter() {
  return createBrowserRouter([
    {
      path: "/",
      element: <Root />,
      children: [{
        path: "/homeDefault",
        element: <HomeDefault />,
      },
      {
        path: "/home1",
        element: <Home1 />,
      },
      {
        path: "/home2",
        element: <Home2 />,
      },
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/explore1",
        element: <Explore1 />,
      },
      {
        path: "/explore",
        element: <Explore />,
        children: [
          {
            index: true,
            element: <ExploreNFT />,
          },
          {
            path: "NFT",
            element: <ExploreNFT />,
          },
          {
            path: "sotoshi",
            element: <ExploreSotoshi />,
          }]
      }, {
        path: "mint",
        element: <Mint />,
      },
      {
        path: "/rangking",
        element: <Rangking />,
      },
      {
        path: "/Auction",
        element: <Auction />,
      },
      {
        path: "/helpcenter",
        element: <Helpcenter />,
      },
      {
        path: "/collection",
        element: <Colection />,
      },
      {
        path: "/ItemDetail/:id",
        element: <ItemDetail />,
      },
      {
        path: "CreatePanel/:greet?",
        element: <CreatePanel />
      },
      {
        path: "Upload",
        element: <UploadFile />
      },
      {
        path: "ItemSelect",
        element: <ItemSelect />,
      },
      {
        path: "Inscribe",
        element: <Inscribe />,
      },
      {
        path: "Detail/:id",
        element: < Detail />,
      },
        ProfileRoots,
      {
        path: "/Author",
        element: <Author />,
      },
      {
        path: "/wallet/:from?",
        element: <WalletLayout />,
      },
      {
        path: "/login",
        element: <Login />,
      },
      {
        path: "/loginTwo",
        element: <LoginTwo />,
      },
      {
        path: "/register",
        element: <Register />,
      },
      {
        path: "/price",
        element: <Price />,
      },
      {
        path: "/works",
        element: <Works />,
      },
      {
        path: "/news",
        element: <News />,
      },
      {
        path: "create",
        element: <Create />
      }, {
        path: "sale/:id",
        element: <Sale />
      },
      {
        path: "/create1",
        element: <Create1 />,
      },
      {
        path: "/create2",
        element: <Create2 />,
      },
      {
        path: "/create3",
        element: <Create3 />,
      },
      {
        path: "/createOptions",
        element: <Createoption />,
      },
      {
        path: "/activity",
        element: <Activity />,
      },
      {
        path: "/contact",
        element: <Contact />,
      },
      {
        path: "/elegantIcons",
        element: <ElegantIcons />,
      },
      {
        path: "/etlineIcons",
        element: <EtlineIcons />,
      },
      {
        path: "/fontAwesomeIcons",
        element: <FontAwesomeIcons />,
      },
      {
        path: "/accordion",
        element: <Accordion />,
      },
      {
        path: "/alerts",
        element: <Alerts />,
      },
      {
        path: "/progressbar",
        element: <Progressbar />,
      },
      {
        path: "/tabs",
        element: <Tabs />,
      },
      {
        path: "/test",
        element: <Test />,
      },],
    },

  ]);
}
export default App;