import Grid from '@mui/material/Unstable_Grid2'
import { ReactNode } from 'react'

const GridLayout = <T,>(props: { data: T[], content: (data: T) => ReactNode }) => {
    return <>
      <Grid container spacing={2}>
        {
          props.data.map((data, index) => (
            <Grid xs={12} md={6} lg={3} key={index}>
              {props.content(data)}
            </Grid>
          ))
        }
      </Grid>
    </>
  }

export default GridLayout