import React, { CSSProperties, Component, HTMLAttributes, HtmlHTMLAttributes, InputHTMLAttributes, useEffect, useMemo, useRef, useState } from "react";
import Clock from "../components/Clock";
import Footer from '../components/footer';
import styled, { createGlobalStyle } from 'styled-components';
import { useNavigate, useParams } from "react-router-dom";
import { get_detail } from "../../thirdSystem/merchandise";
import { ZodParsedType, z } from "zod";
import { Avatar, Box, Button, Card, CardContent, CardMedia, Chip, Container, CssBaselineProps, Paper, Stack, Tab, TextField, Typography } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2'
import TabContext from "@mui/lab/TabContext";
import { faFile, faGem, faHourglass, faHourglass1, faHourglass2, faHourglass3, faTag } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { HandleInput } from "../../common/util";


const GlobalStyles = createGlobalStyle`
  header#myHeader.navbar.sticky.white {
    background: #403f83;
    border-bottom: solid 1px #403f83;
  }
  header#myHeader.navbar .search #quick_search{
    color: #fff;
    background: rgba(255, 255, 255, .1);
  }
  header#myHeader.navbar.white .btn, .navbar.white a, .navbar.sticky.white a{
    color: #fff;
  }
  header#myHeader .dropdown-toggle::after{
    color: rgba(255, 255, 255, .5);
  }
  header#myHeader .logo .d-block{
    display: none !important;
  }
  header#myHeader .logo .d-none{
    display: block !important;
  }
  @media only screen and (max-width: 1199px) {
    .navbar{
      background: #403f83;
    }
    .navbar .menu-line, .navbar .menu-line1, .navbar .menu-line2{
      background: #fff;
    }
    .item-dropdown .dropdown a{
      color: #fff !important;
    }
  }
`;

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: 'none',
    //fontWeight: theme.typography.fontWeightRegular,
    //fontSize: theme.typography.pxToRem(15),
    //marginRight: theme.spacing(1),

    border: '0.25px',
    borderStyle: 'solid',
    padding: 20,

    //   '>button':{

    //   },
    color: 'rgba(0, 0, 0, 0.7)',
    '&.Mui-selected': {
      //color: 'blue',
      '.MuiTab-iconWrapper': {
        color: '#8966e2'
      }

    },
    '&.Mui-focusVisible': {
      backgroundColor: 'rgba(100, 95, 228, 0.32)',
    },
  }),
);
const sale_method_types = z.enum(["fixed_price", "time_auction", "open_for_bids"]);
const fixed_sale_method = z.object({
  //type: z.literal(sale_method_types.enum.fixed_price).default(sale_method_types.enum.fixed_price),
  price: z.coerce.number().default(0),
})
const timed_auction_method = z.object({
  //type: z.literal(sale_method_types.enum.time_auction),
  price: z.coerce.number().default(0),
  starting_date: z.coerce.date().default(new Date()),
  expiration_date: z.coerce.date().default(new Date()),
})
const open_for_bids_method = z.object({
  //type: z.literal(sale_method_types.enum.open_for_bids),
  minimum_price: z.coerce.number().default(0),
  starting_date: z.coerce.date().default(new Date()),
  expiration_date: z.coerce.date().default(new Date()),
})
// type A = z.output<typeof fixed_price_to_sale_method>;
// const fixed_price_to_sale_method = 
//   fixed_sale_method
// .transform(fixed=>({type:sale_method_types.enum.fixed_price,...fixed}));

// const timed_auction_to_sale_method = 
// timed_auction_method
// .transform(timed_auction=>({type:sale_method_types.enum.time_auction,...timed_auction}))

// const t =  fixed_price_to_sale_method.sourceType().extend({
//   type:z.literal(sale_method_types.enum.fixed_price),
// });
// type T = z.infer<typeof t>;
// const a = t.parse({})
// const sale_method = z.discriminatedUnion("type", [
//   fixed_sale_method,
//   timed_auction_method,
//   open_for_bids_method,
// ]);
const sale_method = z.union([fixed_sale_method, timed_auction_method, open_for_bids_method]);
type FixedPriceMethod = z.infer<typeof fixed_sale_method>;
type TimedAuctionMethod = z.infer<typeof timed_auction_method>;
type OpenForBidMethod = z.infer<typeof open_for_bids_method>;
type SaleMethod = z.infer<typeof sale_method>;

// interface SaleInfo extends SaleMethod{

// }
const sale_base_info_schema = z.object({
  title: z.string().default(""),
  description: z.string().default(""),
  royalties: z.string().default(""),
})
// const fixed_sale_schema = sale_base_info_schema.extend({
//   type:z.literal(sale_method_types.enum.fixed_price),
//   price:z.coerce.number().default(0),
// })
// const timed_autcion_schema = sale_base_info_schema.extend({
//   type:z.literal(sale_method_types.enum.time_auction),
//   price:z.coerce.number().default(0),
//   starting_date:z.coerce.date().default(new Date()),
//   expiration_date:z.coerce.date().default(new Date()),
// })
// const open_for_bids = sale_base_info_schema.extend({
//   type:z.literal(sale_method_types.enum.open_for_bids),
//   minimum_price:z.coerce.number().default(0),
//   starting_date:z.coerce.date().default(new Date()),
//   expiration_date:z.coerce.date().default(new Date()),
// })
// const sale_info_schema = z.discriminatedUnion("type",[
//   fixed_sale_schema,
//   timed_autcion_schema,
//   open_for_bids]).default({type:sale_method_types.enum.fixed_price})

// type SaleInfo = z.infer<typeof sale_info_schema>;
const FixedPrice = (props: { method: FixedPriceMethod, on_input: (value: FixedPriceMethod) => void }) => {
  const [value, set_value] = useState(props.method);
  // useEffect(()=>{
  //   console.log(`In fixed Price, inputvalue=${JSON.stringify(value)}`)
  //   return ()=>{
  //     console.log(`Fixed clean up, inputvalue=${JSON.stringify(value)}`);
  //   }
  // },[value]);
  const { method, on_input } = props;
  const change_method_info = HandleInput<FixedPriceMethod>((name, value) => set_value(curr => (fixed_sale_method.parse({ ...curr, [name]: value }))))
  const reff = useRef();
  useEffect(() => {
    console.log("ref");
    console.log(reff.current);
  }, [])
  useEffect(() => {
    on_input(value)
  }, [value, on_input]);
  //console.log(`is run again?? value:`);
  // const get_value = () => {
  //   console.log(`render value: ${props.method.price}`);
  //   return props.method.price;
  // }
  return <>
    <Typography variant="h6">Price</Typography>
    <TextField type="number" name="price" defaultValue={method.price} onChange={change_method_info} size="small" ></TextField>
  </>
}
const TimedauctionForm = (props: { method: TimedAuctionMethod, on_input: (value: TimedAuctionMethod) => void }) => {
  //const [value2,set_value2]= useState({name2:""});
  //const [price, set_price] = useState({ name: 1 });

  // useEffect(()=>{
  //   console.log(`In Timed auction, inputvalue=${JSON.stringify(value2)}`)
  //   return ()=>{
  //     console.log(`Timed auction clean up, inputvalue=${JSON.stringify(value2)}`);
  //   }
  // },[value2]);
  const [value, set_value] = useState(props.method);
  const { method, on_input } = props;
  const change_method_info = HandleInput<TimedAuctionMethod>((name, value) => set_value(curr => (timed_auction_method.parse({ ...curr, [name]: value }))))
  useEffect(() => {
    on_input(value)
  }, [value, on_input]);
  const field_names = timed_auction_method.keyof().enum;
  return <>
    <Stack gap={2}>
      <Typography variant="h6">Price</Typography>
      <TextField name={field_names.price} onChange={change_method_info} defaultValue={method.price} helperText="enter minimum bid" size="small"></TextField>
      <Grid container columnSpacing={'50px'}>
        <Grid xs={12} md={6}>
          <Typography variant="h6">Starting date</Typography>
          <input name={field_names.starting_date} type="date" style={{ width: '100%' }}></input>
        </Grid>
        <Grid xs={12} md={6}>
          <Typography variant="h6">Expiration date</Typography>
          <input name={field_names.expiration_date} type="date" style={{ width: '100%' }}></input>
        </Grid>
      </Grid>
    </Stack>
  </>
}
const OpenForBid = (props: { method: OpenForBidMethod, on_input: (value: OpenForBidMethod) => void }) => {

  const [value, set_value] = useState(props.method);
  const { method, on_input } = props;
  const change_method_info = HandleInput<OpenForBidMethod>((name, value) => set_value(curr => (open_for_bids_method.parse({ ...curr, [name]: value }))))
  useEffect(() => {
    on_input(value)
  }, [value, on_input]);
  const field_names = open_for_bids_method.keyof().enum;
  return <>
    <Stack gap={2}>
      <Typography variant="h6">Minimum bid</Typography>
      <TextField name={field_names.minimum_price} onChange={change_method_info} defaultValue={method.minimum_price} helperText="enter minimum bid" size="small"></TextField>
      <Grid container columnSpacing={'50px'}>
        <Grid xs={12} md={6}>
          <Typography variant="h6">Starting date</Typography>
          <input name={field_names.starting_date} type="date" style={{ width: '100%' }}></input>
        </Grid>
        <Grid xs={12} md={6}>
          <Typography variant="h6">Expiration date</Typography>
          <input name={field_names.expiration_date} type="date" style={{ width: '100%' }}></input>
        </Grid>
      </Grid>
    </Stack>
  </>
}
const comps = [
  {
    label: "Fixed Price",
    icon: (style: CSSProperties) => <i className="fa fa-tag" style={style}></i>,
    comp: FixedPrice
  },
  {
    label: "Timed Auction",
    icon: (style: CSSProperties) => <i className="fa fa-hourglass-1" style={style}></i>,
    comp: TimedauctionForm
  },
  {
    label: "Timed Auction",
    icon: (style: CSSProperties) => <i className="fa fa-users" style={style}></i>,
    comp: TimedauctionForm
  }
];

const Sale = () => {
  const user_id = "/img/author_single/author_thumbnail.jpg";

  const { id } = useParams();

  const item = get_detail(user_id, z.coerce.number().parse(id));

  const [method_tab, set_method_tab] = React.useState(0);

  // const data = {
  //   type: 'open_for_bids',
  //   starting_date: new Date(),
  //   expiration_date: new Date(),
  //   minimum_price: 0.01,
  // };

  // const fix_parse = fixed_sale_method.parse(data);
  // const time = timed_auction_method.parse(data);
  // const open = open_for_bids_method.parse(data);
  // const default_data:SaleMethod[] = [fix_parse,time,open];
  const [tab_panels, default_data] = useMemo(() => {
    const data = {
      type: 'open_for_bids',
      starting_date: new Date(),
      expiration_date: new Date(),
      minimum_price: 0.01,
    };

    const fixed_sale = fixed_sale_method.parse(data);
    const time_auction_sale = timed_auction_method.parse(data);
    const open_bid_sale = open_for_bids_method.parse(data);
    const default_data: SaleMethod[] = [fixed_sale, time_auction_sale, open_bid_sale];
    const tab_panels = [
      <FixedPrice method={fixed_sale_method.parse(data)} on_input={data => { default_data[0] = data }} />,
      <TimedauctionForm method={timed_auction_method.parse(data)} on_input={data => default_data[1] = data} />,
      <OpenForBid method={open_for_bids_method.parse(data)} on_input={data => default_data[2] = data} />

    ]

    return [tab_panels, default_data]
  }, [])
  //   const tab_panels = [
  //   <FixedPrice method={fixed_sale_method.parse(data)} on_input={data=>
  //     {
  //       console.log("in fixed");
  //       console.log(data);
  //       default_data[0]=data
  //     }
  //     }/>,
  // <TimedauctionForm method={timed_auction_method.parse(data)} on_input={data=>default_data[1]= data}/>
  // ]
  // console.log(fix_parse);
  // console.log(time);
  // console.log(open);
  console.log(default_data);
  const navigate = useNavigate();
  const result_description = "✨Congratulations on completing your sale!";
  const to_create_panel = () => {
    console.log(default_data[method_tab]);
    navigate(`/CreatePanel/${result_description}`);
  }
  return (
    <>
      <section className='container'>
        <Grid container spacing={2} minWidth='100%'>
          <Grid xs={12} md={5} display={'flex'} justifyContent={'center'} height={'20rem'}>
            <Paper style={{ minWidth: '100%', display: 'flex', justifyContent: 'center' }}>
              <CardMedia
                component={'img'}
                image={item.info.material_src_url}
                style={{ maxHeight: '100%', height: 'auto', objectFit: 'contain', objectPosition: 'center' }}
              ></CardMedia>
            </Paper>
          </Grid>
          <Grid xs={12} md={7}>
            <CardContent data-nmae="info-content" style={{ height: '100%' }}>
              <Stack direction="column" justifyContent={'space-between'} height={'100%'}>
                <Box>
                  <Typography variant="h3">
                    {item.info.name}
                  </Typography>
                  <Box data-name="chip-bar">
                    <Chip label={item.info.category} />
                  </Box>
                </Box>
                <Typography variant="subtitle1">
                  {item.info.description}
                </Typography>
                <Box>
                  <Typography variant="h6">
                    {item.info.price} BTC
                  </Typography>
                </Box>
              </Stack>
            </CardContent>
          </Grid>
          <Grid xs={12}>
            <Stack gap={2}>
              <Box>
                <Typography marginY={2} variant="h6">Select Method</Typography>
                <Stack direction={"row"} gap={2}>
                  {comps.map((comp, index) => {
                    const color = index === method_tab ? "#8364E2" : "#666666";
                    return (<>
                      <Button key={index} variant="outlined" onClick={e => set_method_tab(index)} style={{ border: 'solid', borderRadius: '12px', minWidth: '140px', minHeight: '150px', borderColor: color }}>
                        <Stack gap={2}>
                          {comp.icon({ color, fontSize: '30px' })}
                          <Typography >{comp.label}</Typography>
                        </Stack>
                      </Button>
                    </>)
                  })}
                </Stack>
              </Box>
              {tab_panels.map((element, index) =>
                <div hidden={index !== method_tab}>
                  {element}
                </div>
              )}
              <Box>
                <Typography variant="h6">Royalties</Typography>
                <TextField size="small"></TextField>
              </Box>
              <Button variant="contained" size="large" onClick={to_create_panel}>sale</Button>
            </Stack>
          </Grid>
        </Grid>

      </section>

      <Footer />
    </>
  );
}

export default Sale;
{/* <section className='jumbotron breadcumb no-bg' style={{backgroundImage: `url(${'./img/background/subheader.jpg'})`}}>
            <div className='mainbreadcumb'>
              <div className='container'>
                <div className='row m-10-hor'>
                  <div className='col-12'>
                    <h1 className='text-center'>Create 2</h1>
                  </div>
                </div>
              </div>
            </div>
          </section> */}

{/* <div ><FixedPrice method={fixed_sale_method.parse(data)} on_input={data=>
                {
                  console.log("in fixed");
                  console.log(data);
                  default_data[0]=data
                }
                }/></div>
             <div >
             <TimedauctionForm method={timed_auction_method.parse(data)} on_input={data=>default_data[1]= data}/>
              </div> */}

{/* {
              Array(10).map(v => {
                const t = v === method_tab;
                const [state, set_state] = useState(0);
                return <></>
              })
            } */}
{/* <FixedPrice price={price} set_price={invalue => set_price(invalue + 1)} /> */ }
{/* {comps[method_tab].comp({})} */ }
{/* {comps.map((e,index)=>index=== method_tab &&<div key={index}> {e.comp({})}</div>)} */ }
{/* <TabContext value={tab}>
                            <TabList onChange={on_sale_method_change}  sx={{display:'flex',gap:'1rem'}} >
                            <StyledTab label={<><FontAwesomeIcon size="5x" icon={faTag} /> Fixed price</>}  value={"0"}></StyledTab>
                            <StyledTab icon={<FontAwesomeIcon icon={faHourglass} size="5x" />} label={"Timed auction"} value={"1"}></StyledTab>
                            </TabList>
                            <TabPanel value={"0"}>{comps[0]()}</TabPanel>
                            <TabPanel value={"1"}>{comps[1]()}</TabPanel>
                        </TabContext> */}