
import Footer from '../components/footer';
import { createGlobalStyle } from 'styled-components';
import { Button, Stack, Typography, styled } from "@mui/material";
import { NavLink, useParams } from "react-router-dom";
import { Container } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconDefinition, faBox, faFile, faGem, faTags } from "@fortawesome/free-solid-svg-icons";
import Grid from '@mui/material/Unstable_Grid2'
import { ReactNode } from 'react';

const GlobalStyles = createGlobalStyle`
  @media only screen and (max-width: 1199px) {
    .navbar{
      background: #403f83;
    }
    .navbar .menu-line, .navbar .menu-line1, .navbar .menu-line2{
      background: #fff;
    }
    .item-dropdown .dropdown a{
      color: #fff !important;
    }
  }
`;
const GridIcon = styled(Grid)({
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
})
const IconButton = styled(Button)({
    minWidth: '80%',
    minHeight: '10rem',
    justifyContent: 'start',

})
const PanelButton = (props: { title: ReactNode, description: ReactNode, icon: IconDefinition }) => {
    return <IconButton variant="outlined">
        <Grid container minWidth={1}>
            <Grid xs={11} md={9}>
                <Stack spacing={2}>
                    <Typography textAlign={"start"} variant="subtitle2">
                        {props.title}
                    </Typography>
                    <Typography textAlign={"start"} variant="body2">
                        {props.description}
                    </Typography>
                </Stack>
            </Grid>
            <GridIcon xs={1} md={3}>
                <FontAwesomeIcon icon={props.icon} size="2xl" />
            </GridIcon>
        </Grid>
    </IconButton>
}
const CreatePanel = () => {
    //window.scrollTo(0, 0);
    //✨Congratulations on completing your creation!
    const { greet } = useParams();
    const default_greet = "What are you looking to do?";
    const greeting = greet ?? "What are you looking to do?";

    return (<>
        <GlobalStyles />
        <section className='container'>
            <Container fluid="sm">
                <Stack margin={'2rem'} textAlign={"center"} gap={"2rem"}>
                    <Typography variant="h4">{greeting}</Typography>
                    {greeting === default_greet
                        ? <Typography variant="h6" color={"purple"}>
                            Choose what you want to do
                        </Typography>
                        : <Typography variant="h6" color={"purple"}>
                            Now you can choose what you want to do next
                        </Typography>}
                    <NavLink to="/Upload">
                        <IconButton variant="outlined">
                            <Grid container minWidth={1}>
                                <Grid xs={11} md={9}>
                                    <Stack spacing={2}>
                                        <Typography textAlign={"start"} variant="subtitle2">
                                            Create your idea
                                        </Typography>
                                        <Typography textAlign={"start"} variant="body2">
                                            Upload file to your repository
                                        </Typography>
                                    </Stack>
                                </Grid>
                                <GridIcon xs={1} md={3}>
                                    <FontAwesomeIcon icon={faFile} size="2xl" />
                                </GridIcon>
                            </Grid>
                        </IconButton>
                    </NavLink>
                    <NavLink to="/ItemSelect">
                        <IconButton variant="outlined">
                            <Grid container minWidth={1}>
                                <Grid xs={11} md={9}>
                                    <Stack spacing={2}>
                                        <Typography textAlign={"start"} variant="subtitle2">
                                            Inscriptions the Ordinals/IPs you created:
                                        </Typography>
                                        <Typography textAlign={"start"} variant="body2">
                                            Choose the Satoshis you want<br />
                                            and combine with your Ordinals/IPs to make the most unique <br />
                                            Ordinals collection
                                        </Typography>
                                    </Stack>
                                </Grid>
                                <GridIcon xs={1} md={3}>
                                    <FontAwesomeIcon icon={faGem} size="2xl" />
                                </GridIcon>
                            </Grid>
                        </IconButton>
                    </NavLink>
                    <NavLink to="/">
                        <PanelButton 
                        title="Create Collection"
                        description={<>Create collections to showcase your worldviews,<br></br>
                        or add items to existing Collection you've created.</>}
                        icon={faBox}
                        />
                    </NavLink>
                    <NavLink to="/profile">
                        <IconButton variant="outlined">
                            <Grid container minWidth={1}>
                                <Grid xs={11} md={9}>
                                    <Stack spacing={2}>
                                        <Typography textAlign={"start"} variant="subtitle2">
                                            Put your created Ordinals/IPs for sale:
                                        </Typography>
                                        <Typography textAlign={"start"} variant="body2">
                                            Make the items you created available for others to own your creativity
                                        </Typography>
                                    </Stack>
                                </Grid>
                                <GridIcon xs={1} md={3}>
                                    <FontAwesomeIcon icon={faTags} size="2xl" />
                                </GridIcon>
                            </Grid>
                        </IconButton>
                    </NavLink>
                </Stack>
            </Container>
        </section>
        <Footer />
    </>)
}

export default CreatePanel