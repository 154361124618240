import { FormEvent } from "react"

// export type FormName<T> = {
//     [P in keyof T]: string
// }


export const HandleInput = <T,>(handleInput: (name: keyof T, value: string) => void) => {
    return <E extends HTMLInputElement | HTMLTextAreaElement>(e: FormEvent<E>) => {
        const target = e.target as E;
        const [name, value] = [target.name as keyof T, target.value];
        handleInput(name, value);
    }
}
export const to_form_data = <T extends Object>(value: T) => {
    const form = new FormData()
    Object.entries(value).forEach(([key, value]) => {
        form.append(key, value);
    })
    return form;
}